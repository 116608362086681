@use 'src/styles/variables/colors';

.requestModalDescription {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.requestModalIcon {
  min-width: 160px;
  height: 120px;
}
