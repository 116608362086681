@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

$whiteGradient: linear-gradient(
  90deg,
  rgba(colors.$white, 0) 0%,
  rgba(colors.$white, 1) 35%,
  rgba(colors.$white, 1) 100%
);
$grayGradient: linear-gradient(
  90deg,
  rgba(colors.$greyscale-200, 0) 0%,
  rgba(colors.$greyscale-200, 1) 35%,
  rgba(colors.$greyscale-200, 1) 100%
);

.sortAsc {
  transform: rotate(180deg);
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;

  svg {
    min-width: 20px;
  }
}

.title {
  max-width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  color: colors.$greyscale-600;

  span {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.titleWithSort {
  max-width: calc(100% - 24px);
}

.heading {
  th {
    padding: 12px 8px;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 5;
    min-width: 132px;
    text-align: left;
    border-bottom: 1px solid colors.$greyscale-300;

    button {
      height: 24px;
      span {
        height: 24px;
      }
    }

    &:first-child {
      padding-left: 16px;
      border-radius: 4px 0 0 0;
    }
    &:last-child {
      border-radius: 0 4px 0 0;
      width: 136px;
      min-width: 136px;

      &.threeActions {
        width: 180px;
        min-width: 180px;
      }
    }
    &:hover {
      svg {
        visibility: visible;
      }
    }

    &.stickySetting,
    &.stickyAction {
      position: sticky;
      top: 0;
      right: 0;
      background: $grayGradient;

      & > div {
        display: flex;
        justify-content: flex-end;
        padding-right: 8px;
      }
    }

    &.stickyLeft {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 7;
      border-right: 1px solid colors.$greyscale-300;
      background-color: colors.$greyscale-200;

      &WithSelection {
        left: 45px;
      }
    }

    &.stickyRight {
      position: sticky;
      top: 0;
      right: 0;
      z-index: 6;
      border-left: 1px solid colors.$greyscale-300;
      background-color: colors.$greyscale-200;
    }

    &.selection {
      width: 45px;
      min-width: 45px;
      max-width: 45px;

      &StickyLeft {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 6;
        background-color: colors.$greyscale-200;
      }
    }
  }

  @include for-mobile {
    th {
      width: 160px;
      min-width: 160px;
      padding: 4px;

      &:first-child {
        padding-left: 8px;
      }

      &:last-child {
        width: 90px;
        min-width: 90px;
        padding: 4px 0;
      }
    }
  }
}

.row {
  position: sticky;
  top: 0;
  background-color: colors.$greyscale-200;
  z-index: 7;
}

.insideAccordion {
  .row {
    background-color: colors.$white;
  }

  th {
    border-bottom: none;

    &.selectionStickyLeft,
    &.stickyLeft,
    &.stickyRight {
      background-color: colors.$white;
    }

    &.stickyAction,
    &.stickySetting {
      background: $whiteGradient;
    }
  }
}
