@use 'src/styles/variables/colors';

.box {
  position: relative;
  padding: 8px;
}

.chipBox {
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
  transition: all 0.2s ease-out;
  border-radius: 18px;
}

.animation {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(247, 115, 91, 0.4);
}
