@use 'src/styles/variables/colors';

.label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px;
  cursor: pointer;
  color: colors.$greyscale-600;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;

  &:hover {
    border-radius: 3px;
    background-color: colors.$greyscale-200;
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: black;
    }
  }

  .settingIcon {
    path {
      fill: colors.$greyscale-600;
    }
  }
}

.editTitle {
  color: colors.$greyscale-900;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.dateLabel {
  display: flex;
  align-items: center;
  padding: 3px 8px;
  margin-top: 16px;
  gap: 8px;
  color: colors.$greyscale-700;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-radius: 3px;
  background: colors.$greyscale-200;

  svg {
    height: 16px;
    path {
      fill: black;
    }
  }
}
