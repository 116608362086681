@use 'src/styles/variables/colors';

.capacityField > div {
  width: 160px;
  height: 36px;
}

.datePickerBox {
  max-width: 50%;
}
