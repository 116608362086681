@use 'src/styles/variables/colors';

.form-content {
  background: colors.$white;
  border-radius: 16px;
  box-shadow: 0 10px 18px rgba(77, 83, 87, 0.05);
}

.eye-icon-active {
  & path {
    fill: colors.$greyscale-900;
  }
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .controls {
    padding: 18px 16px;
    margin-left: -24px;
    margin-right: -24px;
    position: sticky;
    bottom: 0;
    background-color: colors.$white;
    border-top: 1px solid colors.$divider;
  }
}
