@use 'src/styles/variables/colors';

.box {
  width: 670px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: colors.$greyscale-600;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.date {
  width: 122px;
  padding: 0 8px;
}

.member {
  width: 170px;
  padding: 0 16px 0 8px;
}

.longOptionalField {
  width: 230px;
  padding: 0 16px 0 8px;
}

.logged {
  width: 115px;
  padding: 0 8px;
}

.note {
  width: 270px;
  padding: 0 8px;
}
