@use 'src/styles/variables/colors';

.requestBox {
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-500;
}
