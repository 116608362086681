@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.twoItemsBox {
  display: flex;
  gap: 48px;

  @include for-mobile {
    flex-direction: column;
    gap: 0;
  }
}

.note {
  margin-bottom: 24px;

  > div {
    height: 80px;
  }
}

.projectColor {
  min-width: 14px;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-right: 8px;
  display: inline-block;
}
