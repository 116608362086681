@use 'styles/variables/colors';

$headingHeight: 60px;

.timelineSettings {
  position: sticky;
  top: 0;
  left: 0;
  background-color: colors.$white;
  z-index: 10;
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  height: $headingHeight;
  min-height: $headingHeight;
  border-bottom: 1px solid colors.$divider;

  &SecondRow {
    top: $headingHeight;
  }

  &Nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 20px;

    &ButtonGroup {
      height: 30px;
      border: 1px solid colors.$light-gray;
      border-radius: 8px;
      box-shadow: none;

      .MuiButton-root {
        height: auto;
        padding: 6px 4px;
      }

      & > :not(:last-child) {
        border-right: 1px solid colors.$light-gray;
      }
    }

    &Next {
      transform: rotate(0deg) scale(-1);
    }

    &TimePeriod {
      padding: 6px 16px;
      color: colors.$secondary;

      &Active {
        background-color: colors.$greyscale-400;

        &:hover {
          background-color: colors.$greyscale-400;
        }
      }
    }

    &Disabled {
      opacity: 0.33;
    }

    button {
      min-width: auto;
    }
  }

  h2 {
    width: max-content;
    font-weight: 600;
    color: colors.$greyscale-600;
    font-size: 15px;
    line-height: 24px;
  }

  .select {
    color: colors.$greyscale-600;
  }
}
