@use 'src/styles/variables/colors';

$headerHeight: 80px;

.container {
  height: auto;
  max-height: max-content;
}

.form {
  min-height: calc(100vh - #{$headerHeight});
  height: auto;
}

.buttons {
  display: flex;
  padding: 18px 16px;
  gap: 8px;
  margin-left: -24px;
  margin-right: -24px;
  position: sticky;
  bottom: 0;
  box-shadow: 0px -4px 20px rgba(87, 126, 148, 0.15);
  background-color: colors.$white;
  border-top: 1px solid colors.$divider;
}
