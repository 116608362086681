@use 'src/styles/variables/colors';

$title: 150px;
$paymentDate: 167px;
$amount: 104px;
$notes: 136px;
$menu: 52px;

.header {
  display: flex;
  gap: 8px;
  height: 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: colors.$greyscale-600;
  text-transform: uppercase;
}

.title {
  display: flex;
  align-items: center;
  flex: 1 1 $title;
}

.amount {
  display: flex;
  align-items: center;
  flex: 1 1 $amount;
}

.paymentDate {
  display: flex;
  align-items: center;
  flex: 1 1 $paymentDate;
}

.notes {
  display: flex;
  align-items: center;
  flex: 1 1 $notes;
}

.menu {
  width: $menu;
}
