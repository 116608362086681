@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  width: 100%;
  min-width: 480px;
  padding: 16px 16px 4px;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid colors.$greyscale-300;
  background-color: colors.$gray;

  @include for-tablet {
    flex-wrap: wrap;
    gap: 4px;
  }

  @include for-mobile {
    min-width: 280px;
    flex-wrap: wrap;
    gap: 4px;
    margin: 16px 8px 4px;
  }
}

.title {
  min-height: 24px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid colors.$greyscale-300;
  margin-bottom: 16px;
  color: colors.$black;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.infoBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 700px;
  gap: 10px;
  color: colors.$greyscale-600;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
}

.box {
  width: fit-content;
  max-width: 316px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  @include for-mobile {
    min-width: 136px;
    padding: 4px;
    gap: 2px;
  }
}

.value {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: colors.$greyscale-900;
}

.hiddenRolesTooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  margin: -9px -7px;
  background-color: colors.$white;
  box-shadow: 0 4px 15px 0 #00000026;
  border-radius: 4px;
}
