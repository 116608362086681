@use 'src/styles/variables/colors';

.filters {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  padding-right: 20px;

  &Search {
    width: auto;
  }

  &Select {
    &Adornment {
      display: flex;
      align-items: center;
      margin-right: -12px;
    }
  }
}
.filters .select {
  color: colors.$greyscale-600;
}

.groupBtn {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: colors.$greyscale-600;
  margin-right: 12px;
}

.hideRequest {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: colors.$greyscale-600;
  margin-left: 12px;
  margin-right: 4px;
}
