@use 'src/styles/variables/colors';

.badge {
  height: 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: colors.$greyscale-700;
  background: colors.$white;
  cursor: default;
}

.colorMarker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}
