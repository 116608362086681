@use 'sass:map';
@use 'src/styles/variables/colors';
@use 'src/styles/variables/spacing';
@use 'src/styles/variables/typo';

div.tabs {
  width: 250px;
  border-radius: 4px;
  background: colors.$gray;
  padding: 1px;

  a {
    font-size: map.get(typo.$sizes, '14');
    font-weight: typo.$bold;
    width: 100%;
    border-radius: 4px;
    margin-right: 0;
    padding: 10px 16px;
    background: white;
    &:hover {
      background-color: colors.$gray;
    }
  }
  a:not(:last-child) {
    margin-bottom: 1px;
  }
}

a.active {
  &::after {
    bottom: 0;
  }
}
