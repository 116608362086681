@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

div.heading {
  margin-bottom: 0;

  .headingBox {
    @include for-mobile {
      overflow-x: auto;
      display: flex;
      gap: 16px;
    }
  }
}

.memberHeader {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 24px 30px 4px;

  @include for-mobile {
    margin: 0;
  }
}

.tabs {
  @include for-mobile {
    height: 36px;
    overflow-y: hidden;
    overflow-x: auto;
  }
}

.totalAssignments {
  flex-basis: 210px;
  flex-shrink: 0;
  border-right: 1px solid #f2f6f7;
}
.totalAssignmentsValue {
  margin-bottom: 2px;
}

.overflowYAuto {
  min-height: 100%;
}

div.layout {
  padding-top: 0;
  overflow-y: auto;
  z-index: 800;

  @include for-mobile {
    overflow: initial;
  }
}

.editButton {
  margin-left: 16px;
  font-size: 14px;
  color: colors.$secondary;

  & .icon {
    width: 20px;
    margin-right: -4px;
    path {
      fill: colors.$greyscale-550;
    }
  }
}

.stickyTabs {
  position: sticky;
  top: 63px;
  z-index: 800;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 12px;
  z-index: 1000;
}

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activityHistoryButton {
  padding: 7px;
  border-radius: 4px;

  path {
    fill: colors.$greyscale-600;
  }
}
