@use 'src/styles/variables/colors';

.container {
  margin-bottom: 24px;

  .accordionDetails {
    padding: 12px 0 0;
  }

  .accordionSummary {
    margin: 0 -12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: colors.$greyscale-600;
  }
}

.count {
  height: 20px;
  padding: 0 6px 2px 6px;
  margin-left: 4px;
  border-radius: 4px;
  background-color: colors.$greyscale-300;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.count {
  height: 20px;
  padding: 0 6px 2px 6px;
  border-radius: 4px;
  background-color: colors.$greyscale-300;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button {
  height: 52px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  & .icon {
    width: 16px;
    margin-right: -4px;
    path {
      fill: colors.$secondary;
    }
  }
}
