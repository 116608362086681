@use 'src/styles/variables/colors';

span.checkBox {
  display: flex;
  margin: 0;
  height: 20px;
  width: 20px;

  span {
    height: 24px;
  }
}

.checkBox {
  path {
    fill: colors.$greyscale-400;
  }
}

.checked {
  path {
    fill: colors.$greyscale-900;
  }
}

.indeterminate {
  path {
    fill: colors.$greyscale-900;
  }
}
