@use 'src/styles/variables/colors';

.button {
  height: 52px;
  margin-left: 168px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  &:disabled {
    & .icon {
      path {
        fill: colors.$black;
        opacity: 0.26;
      }
    }
  }

  & .icon {
    width: 16px;
    margin-right: -4px;
    path {
      fill: colors.$secondary;
    }
  }
}

.expandIconBox {
  width: 20px;

  &:hover {
    border-radius: 50%;
    background-color: colors.$button-hover;
  }
}

.expandIcon {
  position: absolute;
  left: 0;
}

.container {
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  margin-bottom: 8px;

  .accordion {
    border-radius: 4px;
  }

  .accordionSummary {
    height: auto;
    min-height: 52px;

    & > div {
      margin: 0;
    }
  }
  .accordionExpand {
    padding: 0;
  }
}
