.container {
  display: flex;
  height: 200px;
  min-height: 200px;

  .emptyState {
    position: absolute;
    top: 37px;
    bottom: 0;
    right: 0;
    left: 0;

    span {
      text-transform: uppercase;
    }
  }
}
