@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.formBox {
  padding: 16px;

  @include for-mobile {
    width: 338px;
    padding: 12px;
  }
}

.inputWrapper {
  padding: 0;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2px 0;

  .inputLabel {
    font-size: 14px;
    font-weight: 600;
  }

  .empty {
    color: colors.$secondary;
  }

  .weekend {
    color: colors.$greyscale-400;
  }

  .note {
    position: absolute;
    right: 0;
  }

  .leaveIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -10px;
    left: -2px;

    @include for-mobile {
      left: -4px;
    }
  }

  .warningIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: -10px;
    left: 12px;

    @include for-mobile {
      left: 8px;
    }
  }
}

.popover {
  .form {
    padding: 0;
    width: 821px;

    @include for-mobile {
      width: auto;
    }
  }

  .formByPeriod {
    width: 404px;

    @include for-mobile {
      width: 340px;
    }
  }
}

.tabList {
  display: flex;
  align-items: flex-end;
  height: 40px;
  margin: 0;
  border-bottom: 1px solid colors.$divider;

  button {
    height: 30px;
    margin: 0;
  }

  .tab {
    width: 116px;

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
    }
  }

  .selectedTab {
    border-bottom: 2px solid colors.$greyscale-900;
  }
}

.tabPanel {
  padding: 16px;

  @include for-mobile {
    padding: 12px;
  }
}
