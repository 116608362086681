@use 'src/styles/variables/colors';

.container {
  margin: 18px 0 14px;
}

.roleSelect {
  width: 50%;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}
