@use 'src/styles/variables/colors';

.warningBlock {
  background: colors.$yellow-badge;
  border: 1px solid colors.$yellow-border;
  border-radius: 2px;
  padding: 12px 40px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-900;

  &Icon {
    position: absolute;
    top: 12px;
    left: 16px;
  }
}
