@use 'src/styles/variables/colors';

.box {
  width: 420px;
  min-width: 420px;
  height: 212px;
  padding: 10px 16px 16px;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid colors.$greyscale-300;
}

.titleBox {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid colors.$greyscale-300;
  margin-bottom: 16px;
  color: colors.$black;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.chartBox {
  width: 124px;
  min-width: 124px;
  height: 124px;
}

.legend {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.emptyState {
  margin: 54px 0;
}

.projectName {
  max-width: 162px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projectItem {
  height: 24px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: colors.$greyscale-700;
  cursor: default;
}

.tooltip {
  height: 24px;
}

.hiddenProjectTooltip {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  margin: -9px -7px;
  background-color: colors.$white;
  box-shadow: 0 4px 15px 0 #00000026;
  border-radius: 4px;
}

.colorMarker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}
