@use 'src/styles/variables/colors';
@use 'src/styles/variables/fonts';

.dialog {
  width: 640px;
}

.balance {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 2px 6px;
  font-weight: 600;
  font-size: 14px;
  color: colors.$greyscale-900;
  cursor: pointer;

  &:hover {
    path {
      fill: colors.$greyscale-700;
    }
  }
}

.editIcon {
  max-width: 16px;
  height: 16px;
  margin-left: 4px;
}

.secondaryColor {
  color: colors.$greyscale-600;
}
