@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.buttonContainer {
  display: flex;
  gap: 8px;
}

.filterButton {
  padding-right: 8px;
  color: colors.$greyscale-600;
  background: colors.$white;

  &:hover {
    background: colors.$gray;
  }

  svg {
    margin-left: 4px;
  }

  path {
    fill: colors.$greyscale-600;
  }
}

.tooltipTitle {
  line-height: 20px;
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include for-large-desktop {
    max-width: 140px;
  }
}

.valueButton {
  padding-right: 8px;
  color: colors.$greyscale-700;
  background: colors.$yellow-button;
  border-color: colors.$greyscale-500;

  &:hover {
    background: colors.$yellow-badge;
  }

  svg {
    margin-left: 4px;
  }

  path {
    fill: colors.$greyscale-600;
  }
}

.buttonIcon {
  width: 20px;
  margin-right: -4px;
}

.filterCount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  margin-left: 8px;
  height: 16px;
  background: colors.$failure;
  border-radius: 18px;
  font-weight: 500;
  font-size: 12px;
  color: colors.$white;
}

.box {
  width: 300px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 15px 0 colors.$box-shadow;
}

.title {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  color: colors.$greyscale-600;
  text-transform: uppercase;
}

.form {
  max-height: 400px;
  width: 100%;
}

.formBox {
  overflow-y: auto;

  > :last-child {
    margin-bottom: 0;
  }
}

.optionBox {
  display: flex;
  gap: 8px;
  max-width: 260px;
  word-break: break-word;
  margin-bottom: 8px;
}

.checkboxLabelText {
  color: colors.$greyscale-900;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.buttons {
  display: flex;
  gap: 12px;
  padding-top: 16px;
  background-color: colors.$white;
}
