@use 'src/styles/variables/colors';

.table {
  width: 100%;
  border-radius: 5px;
  border: 1px solid colors.$greyscale-300;
  overflow-y: hidden;

  table {
    border-collapse: separate;
  }
}

.infiniteScrollTable {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;

  table {
    overflow-y: hidden;
    flex-grow: 1;
  }
}

.insideAccordion {
  border-radius: 0 0 5px 5px;
  border: 1px solid colors.$greyscale-300;
}

.infiniteScrollTBody {
  overflow-y: hidden;
  border-collapse: collapse;
}
