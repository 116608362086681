@use 'src/styles';

.tab {
  color: styles.$secondary;
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 24px;
  margin-right: 24px;
  &:hover {
    color: styles.$greyscale-900;
  }
}

.active {
  color: styles.$greyscale-900;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: styles.$greyscale-900;
    position: absolute;
    bottom: -6px;
    left: 0;
    border-radius: 100px;
  }
}
