@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.datePickersBox {
  display: flex;
  gap: 16px;

  @include for-mobile {
    gap: 2px;
  }
}

.twoItemsBox {
  display: flex;
  gap: 48px;

  @include for-mobile {
    flex-direction: column;
    gap: 0;
  }
}
