@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  position: relative;
  padding: 0 22px 32px 40px;
  border-left: 1px solid colors.$greyscale-300;

  @include for-mobile {
    padding: 0 0 32px 20px;
  }
}

.icon {
  position: absolute;
  top: 0;
  left: -20px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: colors.$greyscale-900;
}

.text {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-left: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-500;

  @include for-mobile {
    margin-left: 8px;
  }
}
