@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.teamCell {
  display: flex;
  flex-direction: column;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid colors.$divider;
}

.label {
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 24px;
  font-weight: typo.$bold;
  color: colors.$typo-primary;
}

.value {
  font-size: 22px;
  font-weight: typo.$bold;
  color: colors.$secondary;
  line-height: 32px;

  &.active {
    color: colors.$accent;
  }
}
