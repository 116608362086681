@use 'src/styles/variables/colors';

.popoverContainer {
  width: 250px;
  max-height: 700px;
  overflow-y: hidden;

  .inputRoot {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 8px !important;
    gap: 8px;
    background-color: colors.$greyscale-200;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    fieldset {
      border-color: colors.$greyscale-300 !important;
    }
  }

  .autocompletePopper {
    position: static;
  }

  .groupLabel {
    top: 0;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: colors.$greyscale-600;
  }

  .noOptions {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: colors.$greyscale-600;
  }

  .listBox {
    padding: 0;

    li {
      padding: 0;

      &:hover {
        background-color: inherit;
      }
    }

    .option {
      margin: 0 12px 6px;
      border-radius: 6px;
      padding: 4px;

      &:hover {
        background-color: colors.$greyscale-200;
      }
    }
  }

  .textFieldRoot {
    input {
      min-width: 100px;
      max-height: 18px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: colors.$greyscale-800;
    }
  }
}

.optionTagTooltip {
  max-width: 206px;
}

.optionCreateTagTooltip {
  max-width: 154px;
}

.valueButton {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  font-style: inherit;
  padding: 4px;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background-color: colors.$greyscale-300;
    border-radius: 3px;
  }
}

.addTag {
  border: 1px solid colors.$greyscale-300;
  border-radius: 4px;
}

.addTagTooltip {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-600;
}

.addTagIcon {
  height: 14px;
  width: 14px;
  margin-right: 2px;

  path {
    fill: colors.$greyscale-600;
  }
}

.tagsValue {
  display: flex;
  gap: 2px;
  overflow-x: hidden;
}

.createText {
  margin-right: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: colors.$greyscale-600;
}
