.box {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;

  > div {
    height: 100%;
    width: 100%;
  }
}
