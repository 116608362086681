@use 'src/styles/variables/colors';

.noteButton {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-600;
}

.addIcon {
  margin-left: 4px;

  svg {
    height: 16px;
    width: 16px;
  }

  path {
    fill: colors.$greyscale-600;
  }
}

.hideNoteButton {
  position: absolute;
  top: -14px;
  right: 16px;
  padding: 3px;
  border: 1px solid colors.$greyscale-300;
  background-color: colors.$white;

  &:hover {
    background-color: colors.$greyscale-200;
  }
}

.showNoteIcon {
  transform: rotate(-180deg);
}

.noteTextarea {
  margin-bottom: 16px;

  textarea {
    height: 100%;
    padding: 10px 0;
  }
}
