@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

$whiteGradient: linear-gradient(
  90deg,
  rgba(colors.$white, 0) 0%,
  rgba(colors.$white, 1) 35%,
  rgba(colors.$white, 1) 100%
);
$grayGradient: linear-gradient(
  90deg,
  rgba(colors.$greyscale-200, 0) 0%,
  rgba(colors.$greyscale-200, 1) 35%,
  rgba(colors.$greyscale-200, 1) 100%
);
$yellowGradient: linear-gradient(
  90deg,
  rgba(colors.$yellow-badge, 0) 0%,
  rgba(colors.$yellow-badge, 1) 35%,
  rgba(colors.$yellow-badge, 1) 100%
);

.tableBody {
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}

.column {
  border-bottom: 1px solid colors.$divider;
  height: 45px;
  padding: 5px 8px;

  @include for-mobile {
    padding: 4px;
  }
}

.row,
.rowBeforeFooter {
  position: relative;
  z-index: 0;

  td:first-child {
    padding-left: 16px;
  }

  & .stickyAction {
    position: sticky;
    right: 0;
    background: $whiteGradient;
  }

  & .stickySettings {
    position: sticky;
    right: 0;
    min-width: 50px;
    max-width: 50px;
    background: $whiteGradient;
  }

  & .stickyLeft {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 6;
    background: colors.$white;
    border-right: 1px solid colors.$divider;

    &WithSelection {
      left: 45px;
    }
  }

  & .stickyRight {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 6;
    background: colors.$white;
    border-left: 1px solid colors.$divider;
  }

  & .selection {
    width: 45px;
    min-width: 45px;
    max-width: 45px;

    &StickyLeft {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 6;
      background: colors.$white;
    }
  }

  & .expanded {
    position: sticky;
    top: 48px;
    z-index: 20;
  }

  &:hover {
    background: colors.$greyscale-200;

    & .stickyAction,
    .stickySettings {
      background: $grayGradient;
    }

    & .stickyLeft {
      z-index: 5;
      background: colors.$greyscale-200;
      border-right: 1px solid colors.$divider;
    }

    & .stickyRight {
      z-index: 5;
      background: colors.$greyscale-200;
      border-left: 1px solid colors.$divider;
    }

    & .selection {
      z-index: 5;
      background: colors.$greyscale-200;
    }

    & .depth {
      background: colors.$greyscale-200;
    }

    & .warning {
      background: colors.$yellow-badge;
    }

    & .unselected {
      background: colors.$greyscale-200;
    }

    & td {
      border-color: colors.$light-gray;
      z-index: 2;
      position: sticky;

      &:first-child {
        padding-left: 16px;
      }

      &:before {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        content: '';
        height: 1px;
        background-color: colors.$light-gray;
        z-index: 3;
      }
    }
  }

  &Link {
    display: contents;
  }

  @include for-mobile {
    td:first-child {
      padding-left: 8px;
    }

    td:last-child {
      padding: 4px 0;
    }

    &:hover {
      & td {
        &:first-child {
          padding-left: 8px;
        }
      }
    }
  }
}

.rowBeforeFooter {
  &:last-child .column {
    border-bottom: none;
  }
}

.canExpanded {
  z-index: 4;
  background-color: colors.$white;
  box-shadow: 0px -1px 0px 0px colors.$greyscale-300;
}

.expanded {
  position: sticky;
  top: 48px;
  min-height: 45px;
  z-index: 3;
  background-color: colors.$white;
}

tr.depth {
  background: colors.$gray;
  color: colors.$greyscale-700;

  & .stickyLeft {
    background: colors.$greyscale-200;
  }

  & .stickyRight {
    background: colors.$greyscale-200;
  }

  & .stickySettings {
    background: $grayGradient;
  }
}

tr.warning {
  padding-left: 40px;
  background: colors.$yellow-badge;

  & .selection {
    background: colors.$yellow-badge;
  }

  & .stickyLeft {
    background: colors.$yellow-badge;
  }

  & .stickyRight {
    background: colors.$yellow-badge;
  }

  & .stickySettings {
    background: $yellowGradient;
  }

  & .stickyAction {
    background: $yellowGradient;
  }

  &:hover {
    background: colors.$yellow-badge;

    & .selection {
      background: colors.$yellow-badge;
    }

    & .stickyLeft {
      background: colors.$yellow-badge;
    }

    & .stickyRight {
      background: colors.$yellow-badge;
    }

    & .stickySettings {
      background: colors.$yellow-badge;
    }

    & .stickyAction {
      background: $yellowGradient;
    }
  }
}

tr.unselected {
  background: colors.$gray;
  color: colors.$greyscale-600;
}
