@use 'src/styles/variables/colors';

.header {
  position: sticky;
  top: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding: 24px 24px 16px;
  background: colors.$white;
  z-index: 4;
}

.title {
  margin-right: auto;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}

.outlinedButton {
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$secondary;
  padding: 6px 12px;

  &:disabled {
    & .icon {
      path {
        fill: colors.$black;
        opacity: 0.26;
      }
    }
  }

  & .icon {
    width: 16px;
    margin-right: -4px;
    path {
      fill: colors.$secondary;
    }
  }
}

.button {
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  color: colors.$secondary;

  & .icon {
    width: 20px;
    margin-right: -4px;
    path {
      fill: colors.$secondary;
    }
  }
}

.box {
  margin-top: -16px;
  padding: 0 24px 168px;
}

.icon {
  width: 22px;
  height: 22px;
}

.spinnerBox {
  height: calc(100vh - 330px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
