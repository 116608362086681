@use 'src/styles/variables/colors';

.container {
  position: relative;
  width: 840px;
  padding: 13px 0 24px 32px;
  border-bottom: 1px solid colors.$greyscale-300;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: colors.$greyscale-900;
}

.statusBox {
  display: flex;
  height: 23px;
  padding: 0 6px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.connected {
  color: colors.$greyscale-900;
  background: colors.$accent-300;
}

.disconnected {
  color: colors.$yellow-badge-text;
  background: colors.$yellow-border;
}

.text {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-600;
}

.date {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: auto;
  color: colors.$greyscale-500;
}

.buttonBox {
  display: flex;
  gap: 12px;
  margin-top: 6px;
}

.button {
  font-size: 14px;
  color: colors.$secondary;

  & .icon {
    width: 20px;
    margin-right: -4px;
    path {
      fill: colors.$secondary;
    }
  }
}

.disableIcon {
  path {
    fill: rgba(0, 0, 0, 0.26);
  }
}

.connectButton {
  background-color: colors.$accent;
  color: colors.$white;

  &:hover {
    background-color: colors.$accent-500;
  }
}

.disconnectButton {
  background-color: colors.$failure;
  color: colors.$white;

  &:hover {
    background-color: colors.$red-dark;
  }
}
