@use 'src/styles/variables/colors';

.wrapper {
  background-color: colors.$gray;
  height: 100%;
  min-width: 360px;
  display: flex;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 61px);
}

.header {
  height: 60px;
  display: flex;
  padding: 0 32px;
  align-items: center;
  gap: 8px;
  background-color: black;
}

.buttonText {
  margin-right: 12px;
  font-size: 16px;
  font-weight: 600;
  color: colors.$greyscale-500;
}

.buttonIcon {
  path {
    fill: colors.$greyscale-500;
  }
}
