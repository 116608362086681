$roleRowWidth: 735px;
$halfRoleRowHeight: 40px;
$dragIconWidth: 22px;
$projectSectionHeight: 135px;

.dragLayer {
  left: calc(#{$dragIconWidth} - #{$roleRowWidth});
  bottom: $halfRoleRowHeight;
  width: $roleRowWidth;
}

.container {
  position: relative;
}

.scrollAnchor {
  position: absolute;
  top: -#{$projectSectionHeight};
}
