@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.table {
  overflow: scroll;
  border: 1px solid colors.$divider;
  max-height: 100%;

  .row {
    display: flex;
    border-bottom: 1px solid colors.$divider;

    &:last-child:not(:first-child) {
      border-bottom: none;
    }

    &.odd {
      background-color: colors.$gray;
    }

    &.even {
      background-color: colors.$white;
    }

    &:hover {
      background-color: colors.$greyscale-200;
    }
  }

  .cell {
    position: relative;
    height: 100%;
    padding: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid colors.$divider;

    @include for-mobile {
      padding: 0 4px;
    }

    &.withoutAssignment {
      opacity: 0.5;
    }

    .weekend {
      background-color: colors.$greyscale-200;
    }
  }

  .header {
    top: 0;
  }

  .footer {
    bottom: 0;
  }

  .header,
  .footer {
    position: sticky;
    z-index: 5;
    box-shadow: inset 0 -1px 0 0 colors.$divider;

    .row {
      height: 48px;
    }

    .cell {
      background-color: white;
      font-size: 12px;
      font-weight: 600;
      color: colors.$greyscale-600;

      &.weekend {
        background-color: colors.$greyscale-200;
        color: colors.$greyscale-400;
      }

      &.name {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        justify-content: flex-start;
        padding: 0 24px 0 12px;

        @include for-mobile {
          padding: 0 4px;
        }
      }
    }

    .total {
      font-size: 14px;
      color: colors.$typo-primary;
    }
  }

  .footer {
    .cell {
      background-color: colors.$gray;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      border-top: 2px solid colors.$greyscale-350;
    }

    .footerName {
      justify-content: flex-start;
      padding: 0 24px;

      @include for-mobile {
        padding: 0 4px;
      }
    }
  }

  .body {
    .row {
      height: 48px;

      @include for-mobile {
        height: 48px;
      }
    }
  }

  [data-sticky-td] {
    background-color: inherit;

    .cell {
      border-right: none;
    }
  }

  [data-sticky-last-left-td] {
    border-right: 2px solid colors.$greyscale-350;
    background-color: inherit;
  }

  .empty {
    padding: 36px 0;
    position: sticky;
    left: 0;
  }
}

.headerLabel {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 100%;
}

.weekend {
  background-color: colors.$greyscale-200;
}

.dayCell {
  min-width: 46px;
  flex-basis: 0;
  flex-grow: 1;
}
