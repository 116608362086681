@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.billableIcon {
  path {
    fill: colors.$typo-primary;
    &:nth-child(2) {
      stroke: colors.$typo-primary;
    }
  }
}
