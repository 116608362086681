@use 'src/styles/variables/colors';

.wrapper {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  display: grid;
  place-content: center;
  cursor: pointer;

  &.active {
    border: 3px solid colors.$white;
    outline: 1px solid colors.$secondary;
  }
}

.popper {
  border-radius: 4px;
  background-color: colors.$white;
  z-index: 1300;
  margin-top: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid colors.$transparent-gray;

  .grid {
    display: grid;
    grid-template-columns: repeat(9, 32px);
    grid-template-rows: repeat(3, 32px);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 12px;
    background-color: colors.$white;
    border-radius: 4px;

    .item {
      border-radius: 6px;
      cursor: pointer;
    }
    .selected {
      border: 3px solid colors.$white;
      outline: 1px solid colors.$greyscale-500;
    }
  }
}

.arrow {
  width: 3em;
  height: 3em;
  border: 1px solid colors.$transparent-gray;
  background-color: colors.$white;
  position: absolute;
  top: -4px;
  left: 1em;
  transform: rotate(45deg);
  z-index: -1;
}

.editIcon {
  path {
    fill: colors.$white;
  }
}
