@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.detailInfo {
  border-right: 1px solid colors.$gray;
  border-bottom: 1px solid colors.$gray;
}

.detailInfoLastChild {
  border-bottom: 1px solid colors.$gray;
}

.detailInfoEndMargin {
  margin-bottom: 2px;
}

div.heading {
  .headingBox {
    @include for-mobile {
      overflow-x: auto;
    }
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  gap: 12px;
}

div.layout {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: space-between;
}

.overflowYAuto {
  overflow-y: auto;
  min-height: 100%;
}

.editProjectPaper {
  width: 688px;

  @include for-mobile {
    width: 360px;
  }
}
