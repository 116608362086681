@use 'src/styles/variables/colors';

.dialog {
  width: 400px;

  &Content {
    padding: 24px 0;
    min-height: 260px;
    overflow-x: hidden;
  }
}

.radioItem {
  color: colors.$greyscale-900;
}

.radioItemContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0 4px 24px;
}

.tag {
  box-sizing: border-box;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid colors.$greyscale-300;
  max-width: 200px;
  margin: 0 2px 2px 0;
}
