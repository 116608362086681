@use 'src/styles/variables/colors';

.listBox {
  font-size: 1.4rem;
  font-weight: 600;
  color: colors.$typo-primary;
  max-height: 330px;
  overflow-y: scroll;

  & > li {
    padding: 0;

    &[aria-selected='true'] {
      background-color: colors.$greyscale-300;
    }

    &:hover {
      background: colors.$greyscale-200;

      &[aria-selected='true'] {
        background-color: colors.$greyscale-300;
      }
    }
  }
}

.chip {
  border-radius: 4px;
  border: 1px solid colors.$light-gray;
}

.input {
  &Multiple {
    &:first-child {
      height: auto;
    }
  }
}

.listItem {
  padding: 6px 16px;
}

.creatableListItem {
  width: 100%;
  padding: 16px 0 8px 16px;
  border-top: 1px solid colors.$greyscale-300;
}

.createText {
  margin-right: 4px;
  color: colors.$greyscale-700;
}
