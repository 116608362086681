@use 'src/styles/variables/colors';

.stickyFooter {
  position: sticky;
  bottom: 0;
  background-color: colors.$white;
  box-shadow: 0px -4px 20px rgba(87, 126, 148, 0.15);
  padding: 14px 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  z-index: 10;
}
