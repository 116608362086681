@use 'src/styles/variables/colors';

.dragItem {
  width: 735px;
  padding: 10px 10px 0;
  margin-top: 16px;
  background-color: colors.$white;
  border: 1px solid colors.$greyscale-300;
  border-radius: 4px;
}

.dragging {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.dragIcon {
  margin-left: auto;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 14px;
}
