@use 'src/styles/variables/colors';

.listBox {
  font-size: 1.4rem;
  font-weight: 600;
  color: colors.$typo-primary;
  max-height: 330px;
  overflow-y: scroll;
  border: 1px solid colors.$greyscale-400;
  border-radius: 4px;

  & > li {
    padding: 6px 16px;

    &[aria-selected='true'] {
      background-color: colors.$greyscale-300;
    }

    &:hover {
      background: colors.$greyscale-200;

      &[aria-selected='true'] {
        background-color: colors.$greyscale-300;
      }
    }
  }
}

.chip {
  border-radius: 4px;
  border: 1px solid colors.$light-gray;
}

.root {
  .input {
    &Multiple {
      .tag {
        padding-right: 4px;

        :first-child {
          margin-right: 4px;
        }

        svg {
          min-width: 16px;
          min-height: 16px;
          padding: 4px;
        }
      }
    }
  }
}

.input {
  &Multiple {
    &:first-child {
      height: auto;
    }
  }
}

.listbox {
  overflow-y: auto;
}

.paper {
  box-shadow: 0 4px 15px colors.$box-shadow;
}
