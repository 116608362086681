@use 'src/styles/variables/colors';

$headingHeight: 120px;
$daysHeight: 25px;
$minColumnWidth: 32px;
$minQuarterColumnWidth: 12px;
$minYearColumnWidth: 3px;
$leftSectionWidth: 315px;

.monthlyRow {
  height: $daysHeight;
  min-height: $daysHeight;
  flex-grow: 1;
  display: flex;
  position: sticky;
  top: $headingHeight;
  z-index: 4;
  margin-left: $leftSectionWidth;
  min-width: calc(100% - #{$leftSectionWidth});
  width: max-content;
  border-bottom: 1px solid colors.$divider;
  background: colors.$white;
  line-height: $daysHeight;

  &Item,
  &YearItem,
  &QuarterItem {
    box-sizing: border-box;
    position: relative;
    min-width: $minColumnWidth;
    flex: 1 1 0;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: colors.$secondary;

    &Collapsed {
      min-width: 0;
      flex: 0;
    }

    &RightLightShadow {
      box-shadow: inset -1px 0 0 0 colors.$divider;
    }

    &DoubleRightLightShadow {
      box-shadow: inset -2px 0 0 0 colors.$divider;
    }

    &RightDarkShadow {
      box-shadow: inset -1px 0 0 0 colors.$greyscale-400;
    }

    &WithBackground {
      background-color: colors.$gray;

      &MonthLine {
        background-color: colors.$greyscale-200;
      }
    }
  }

  &QuarterItem {
    min-width: $minQuarterColumnWidth;
    background-color: colors.$greyscale-200;
  }

  &YearItem {
    min-width: $minYearColumnWidth;
    background-color: colors.$greyscale-200;
  }

  &Label {
    white-space: nowrap;
    position: relative;
    z-index: 7;

    &Text {
      position: absolute;
      left: 4px;
    }
  }
}
