@use 'src/styles/variables/colors';

$title: 134px;
$paymentDate: 164px;
$amount: 100px;
$notes: 134px;
$menu: 52px;

.row {
  display: flex;
  align-items: flex-start;
  min-width: 552px;
  min-height: 52px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  background-color: colors.$greyscale-300;
  font-size: 14px;
  font-weight: 600;
}

.item {
  line-height: 18px;
  border-radius: 4px;
}

.title {
  display: flex;
  align-items: center;
  flex: 1 1 $title;
}

.amount {
  display: flex;
  align-items: center;
  flex: 1 1 $amount;
}

.paymentDate {
  display: flex;
  align-items: center;
  flex: 1 1 $paymentDate;
}

.notes {
  display: flex;
  align-items: center;
  flex: 1 1 $notes;
}

.input {
  background-color: colors.$white;

  input {
    padding: 10px 8px;
  }
}

.iconButton {
  padding: 0;
}

.menu {
  padding: 7px 0;
  height: 36px;
  width: $menu;
  display: flex;
  gap: 8px;
}
