@use 'src/styles/variables/colors';

.box {
  width: 789px;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  color: colors.$greyscale-900;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: colors.$gray;
  }
}

.date {
  width: 126px;
  padding: 0 6px;
  cursor: pointer;
}

.member {
  width: 170px;
  cursor: pointer;
}

.logged {
  display: flex;
  align-items: center;
  width: 115px;
  padding: 0 8px;
  cursor: pointer;
}

.overtimeIcon {
  height: 16px;
  width: 16px;

  path {
    fill: colors.$failure;
  }
}

.note {
  width: 384px;
  padding: 0 8px;
  cursor: pointer;
}

.noteText {
  max-width: 188px;
}

.divider {
  width: 1px;
  height: 24px;
  background-color: colors.$greyscale-300;
}
