@use 'src/styles/variables/colors';

.box {
  display: flex;
  padding: 12px 16px;
  margin: 0 24px 24px;
  gap: 8px;
  background: colors.$greyscale-200;
  border: 1px solid colors.$greyscale-400;
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-style: normal;
  color: colors.$greyscale-600;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.title {
  color: colors.$greyscale-900;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.title {
  font-weight: 700;
}

.infoIcon {
  min-width: 20px;
}
