@use 'src/styles/variables/colors';

.container {
  height: 56px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 6px;
  border-radius: 6px;

  &:hover {
    background-color: colors.$greyscale-200;
  }
}

.text {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-800;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-600;
}

.actions {
  width: 160px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.linearProgress {
  width: 160px;
  height: 8px;
  padding: 1px;
  background: linear-gradient(154.62deg, #29c58e -1.83%, #bcef30 120.8%);
  border-radius: 100px;

  div {
    height: 6px;
    border-radius: 100px;
    margin: 1px;
    background: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 2px,
      rgba(255, 255, 255, 0.8) 2px,
      rgba(255, 255, 255, 0.8) 12px
    );
  }
}

.trashIcon {
  path {
    fill: colors.$greyscale-500;
  }
}
