@use 'src/styles/variables/colors';

$leaveTypes: 251px;
$limit: 44px;
$accrualDateMax: 185px;
$accrualDateMin: 165px;
$menu: 52px;

.row {
  display: flex;
  align-items: center;
  min-width: 552px;
  height: 52px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: colors.$greyscale-200;
  }
}

.iconButton {
  width: 22px;
  height: 22px;
  padding: 1px;

  svg {
    min-width: 18px;
    min-height: 18px;
  }
}

.item {
  margin-left: 8px;
  color: colors.$greyscale-900;
  cursor: pointer;
}

.divider {
  width: 1px;
  min-width: 1px;
  height: 24px;
  margin: -4px 0;
  background-color: colors.$greyscale-300;
}

.leaveTypes {
  width: $leaveTypes;
}

.leaveTypesTooltip {
  max-width: $leaveTypes;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.limit {
  max-width: $limit;
  min-width: $limit;
}

.accrualDate {
  display: flex;
  flex-grow: 1;
  max-width: $accrualDateMax;
  min-width: $accrualDateMin;
}

.menu {
  width: $menu;
  display: flex;
  gap: 8px;
}

.secondaryColor {
  color: colors.$greyscale-500;
}
