@use 'src/styles/variables/colors';

.box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.button {
  height: 52px;
  margin-top: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  & .icon {
    width: 16px;
    margin-right: -4px;
    path {
      fill: colors.$secondary;
    }
  }
}
