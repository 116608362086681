@use 'src/styles/variables/colors';

.defaultText {
  color: colors.$secondary;
}

.container {
  width: auto;
}

.input {
  &Root {
    width: auto;

    &:hover {
      background-color: colors.$gray;
    }

    .select {
      width: auto;
      color: colors.$greyscale-700;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      padding: 10px 32px 10px 16px;

      &WithoutIcon {
        padding-right: 10px;
      }
    }
  }
}

.notchedOutline {
  border: none;
}

.check {
  display: none;
}

.menu {
  max-height: 600px;
}

.menuItem {
  &:not(:global(.d-none)) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  .check {
    display: inline-block;
    margin-left: 10px;
  }
}

.additionalText {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
  color: colors.$greyscale-500;
}

.selectMenuTitle {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  opacity: 1;
  padding-right: 24px;
}
