@use 'src/styles/variables/colors';
@use 'src/styles/variables/fonts';

.container {
  width: 264px;
  min-height: 172px;
  height: auto;
  padding: 12px;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: colors.$greyscale-900;
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 16px;
}

.label {
  display: flex;
  align-items: center;
  font-family: fonts.$primary-font;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    cursor: pointer;

    path {
      fill: colors.$greyscale-700;
    }
  }
}

.icon {
  margin-left: 4px;
  max-width: 16px;
  height: 16px;
}
