@use 'src/styles/variables/colors';

$headingHeight: 120px;
$daysHeight: 25px;
$minColumnWidth: 32px;
$minQuarterColumnWidth: 12px;
$leftSectionWidth: 315px;
$calendarWeek: 7;

.dailyRow {
  height: $daysHeight;
  min-height: $daysHeight;
  flex-grow: 1;
  display: flex;
  position: sticky;
  z-index: 4;
  margin-left: $leftSectionWidth;
  min-width: calc(100% - #{$leftSectionWidth});
  width: max-content;
  border-bottom: 1px solid colors.$divider;
  background: colors.$white;
  line-height: $daysHeight;
  top: $headingHeight + $daysHeight;

  &Item {
    box-sizing: border-box;
    position: relative;
    min-width: $minColumnWidth;
    flex: 1 1 0;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: colors.$secondary;

    &Current {
      background-color: colors.$greyscale-500;
      color: colors.$white;
    }

    &Collapsed {
      min-width: 0;
      flex: 0;
    }

    &RightLightShadow {
      box-shadow: inset -1px 0 0 0 colors.$divider;
    }

    &DoubleRightLightShadow {
      box-shadow: inset -2px 0 0 0 colors.$divider;
    }

    &WeekendIcon {
      display: none;
      width: 20px;
      height: 20px;
      position: absolute;
      cursor: pointer;
    }

    &SaturdayIcon {
      display: block;
      top: -10px;
      left: -3px;
      z-index: 7;

      &Disabled {
        display: none;
      }
    }

    &SundayIcon {
      display: block;
      transform: rotate(180deg);
      top: -10px;
      right: -2px;
      z-index: 7;
    }

    &WithBackground {
      background-color: colors.$gray;
    }
  }
}

.hoverWeekendsSection {
  position: absolute;
  height: 200%;
  width: 200%;
  left: 0;
  top: -100%;
  z-index: 8;
  cursor: pointer;

  &Collapsed {
    height: 20px;
    width: 40px;
    left: -20px;
    top: -10px;

    &Last {
      width: 20px;
    }
  }
}

.weeklyItem {
  box-sizing: border-box;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  color: colors.$secondary;
  background-color: colors.$gray;
  flex: $calendarWeek 1 0;

  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &Active {
    color: colors.$white;
    background-color: colors.$greyscale-500;
  }
}
