.dragItem {
  position: relative;
  opacity: 1;
}

.dragPreviewItem {
  transform: translate(0, 0);
}

.dragging {
  opacity: 0;
}

.dragHandle {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
}
