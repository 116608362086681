@use 'src/styles/variables/colors';

.box {
  display: flex;
  align-items: center;
  border-radius: 3px;
  max-height: 20px;
  width: fit-content;
  padding: 0 6px;
  font-size: 13px;
  white-space: nowrap;

  &.gray {
    background: colors.$greyscale-300;
    color: colors.$greyscale-800;
  }

  &.active {
    background: colors.$green-transparent;
    color: colors.$green-text;
  }

  &.disabled {
    background: colors.$yellow-badge;
    color: colors.$yellow-badge-text;
  }
}

.warningIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
