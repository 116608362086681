@use 'src/styles/variables/colors';

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.count {
  height: 20px;
  padding: 0 6px 2px 6px;
  border-radius: 4px;
  background-color: colors.$greyscale-300;
}

.tooltip {
  height: 20px;
}
