@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  background-color: colors.$white;
  min-width: 400px;
  box-sizing: border-box;

  @include for-mobile {
    min-width: 360px;
  }
}

.box {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 24px;
  border-bottom: 1px solid colors.$divider;
}

.title {
  color: colors.$greyscale-900;
  font-size: 18px;
  font-weight: 600;
}

.content {
  overflow-y: auto;
  max-height: calc(100vh - 175px);
}
