@use 'src/styles/variables/colors';

.buttonContainer {
  display: flex;
  gap: 8px;
}

.filterButton {
  color: colors.$greyscale-700;
  background: colors.$gray;

  path {
    fill: colors.$greyscale-700;
  }
}

.buttonIcon {
  width: 20px;
  margin-right: -4px;
}

.filterCount {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  margin-left: 8px;
  height: 16px;
  background: colors.$failure;
  border-radius: 18px;
  font-weight: 500;
  font-size: 12px;
  color: colors.$white;
}

.box {
  width: 300px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 15px 0 colors.$box-shadow;
}
