@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.form {
  @include for-mobile {
    width: 316px;
  }
}

.periodTypeInput {
  padding-top: 24px;
  flex: 1 1;
}

.minuteInputBox {
  width: 30%;
}

.checkboxLabel {
  margin: 0;

  .checkboxLabelText {
    color: colors.$secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .active {
    color: colors.$greyscale-900;
  }
}
