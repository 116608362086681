@use 'src/styles/variables/colors';

.form {
  max-height: 400px;
  width: 100%;
}

.formBox {
  overflow: auto;

  > :last-child {
    margin-bottom: 0;
  }
}

.popoverTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.filterCount {
  display: flex;
  align-items: center;
  height: 17px;
  font-weight: 700;
  font-size: 12px;
  color: colors.$greyscale-500;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid colors.$greyscale-500;
  text-transform: uppercase;
}

.divider {
  margin: 12px 0;
}

.title {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: colors.$greyscale-600;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.tagBox {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  box-sizing: border-box;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.tagBorder {
  border: 1px solid colors.$greyscale-300;
}

.tagSelected {
  max-width: 200px;
  margin: 0 2px 2px 0;
}

.tagTooltip {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-700;
}

.tagIcon {
  height: 16px;
  width: 16px;
  margin-right: 4px;

  path {
    fill: colors.$greyscale-600;
  }
}

.buttons {
  display: flex;
  gap: 12px;
  padding-top: 12px;
  background-color: colors.$white;
}
