@use 'src/styles/variables/colors';

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  text-transform: uppercase;
  border-bottom: 1px solid colors.$divider;
  color: colors.$secondary;
  font-weight: 700;
  cursor: pointer;

  &.error {
    color: colors.$error;
    border-bottom: 1px solid colors.$error;
  }
}

.icon {
  transition: transform 300ms ease-out, color 333ms;
  &.open {
    transform: rotate(-180deg);
  }

  &.error {
    path {
      fill: colors.$error;
    }
  }
}
