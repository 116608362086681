@use 'src/styles/variables/colors';

$minColumnWidth: 32px;
$minQuarterColumnWidth: 12px;
$leftSectionWidth: 315px;
$calendarWeek: 7;

.timelineColumns {
  z-index: 1;
  display: flex;
  position: absolute;
  left: $leftSectionWidth;
  top: 0;
  height: 100%;
  width: calc(100% - #{$leftSectionWidth});

  &DailyItem {
    box-sizing: border-box;
    background-color: colors.$gray;
    flex-grow: 1;
    flex-basis: 0;
    height: 100%;
    min-width: $minColumnWidth;
    border-right: 1px solid colors.$divider;

    &Current {
      background-color: colors.$greyscale-200;
    }

    &Disabled {
      background-color: colors.$white;
      background-image: url('./images/background.svg');
      background-size: calc(100% - 6px);
      background-position-x: 3px;
      background-repeat: repeat-y;
    }

    &Collapsed {
      min-width: 0;
      flex: 0;
      border: none;
    }
  }

  &WeeklyItem {
    box-sizing: border-box;
    position: relative;
    color: colors.$secondary;
    background-color: colors.$gray;
    height: 100%;
    border-right: 1px solid colors.$divider;
    flex: $calendarWeek 1 0;

    &Active {
      background-color: colors.$greyscale-200;
    }
  }
}
