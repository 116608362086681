$mobile: 360px;
$tablet: 768px;
$desktop: 1024px;
$largeDesktop: 1420px;

@mixin for-mobile {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin for-large-desktop {
  @media (min-width: $largeDesktop) {
    @content;
  }
}
