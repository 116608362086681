@use 'src/styles/variables/colors';

.paper {
  width: 783px;

  .box {
    padding: 0;
  }
}

.header {
  margin-bottom: 0;
}
