@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.pointer {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.required:after {
  content: '*';
  position: absolute;
}

.tabs-container {
  height: 80px;
  padding: 22px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-mobile {
    height: auto;
    flex-wrap: wrap;
    gap: 8px 0;
    padding: 12px 8px;
  }
}

.tabs-box {
  height: 40px;
  padding: 10px 30px 4px;
  border-radius: 4px;
  border-bottom: 1px solid colors.$greyscale-300;
  background: colors.$white;
  margin-bottom: 12px;

  @include for-mobile {
    padding: 10px 8px 4px;
    overflow-x: auto;
    min-height: 43px;
  }
}

.dropdown-button-arrow {
  margin: 0 5px;
  transition: transform 0.1s;

  path {
    fill: colors.$white;
  }

  &.open {
    transform: rotate(-180deg);
  }
}

.table-dropdown-button-arrow {
  margin: 0 5px;
  transition: transform 0.1s;
  transform: rotate(-90deg);

  path {
    fill: colors.$white;
  }

  &.open {
    transform: rotate(0deg);
  }
}

.button-group {
  height: 30px;
  border: 1px solid colors.$light-gray;
  border-radius: 8px;
  box-shadow: none;

  & > :not(:last-child) {
    border-right: 1px solid colors.$light-gray;
  }

  &-item {
    height: auto;
    padding: 6px 16px;
    color: colors.$secondary;

    &-active {
      background-color: colors.$greyscale-400;

      &:hover {
        background-color: colors.$greyscale-400;
      }
    }
  }
}
