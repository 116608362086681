@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.select {
  color: colors.$secondary !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 32px 10px 16px;
  width: auto;
}

.filters {
  margin-left: 12px;
  color: colors.$secondary;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  svg {
    path {
      fill: colors.$secondary;
    }
  }
}

.viewHeading {
  color: colors.$greyscale-900;

  @include for-mobile {
    padding-left: 8px;
    padding-right: 8px;
  }
}

button.filterButton {
  background: none;
}
