@use 'variables/colors';
@use 'variables/fonts';

html {
  font-size: 62.5%;
}

body {
  font-family: fonts.$primary-font;
  color: colors.$typo-primary;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

html,
body,
#root {
  height: 100%;
}

th,
td {
  vertical-align: middle;
}
