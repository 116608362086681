@use 'src/styles/variables/colors';

$leaveTypes: 266px;
$limit: 62px;
$accrualDate: 182px;

.header {
  display: flex;
  gap: 8px;
  height: 20px;
  padding-left: 18px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: colors.$greyscale-600;
  text-transform: uppercase;
}

.leaveTypes {
  width: $leaveTypes;
}

.limit {
  width: $limit;
}

.accrualDate {
  width: $accrualDate;
}
