@use 'src/styles/variables/colors';

.link {
  max-width: 100%;
  cursor: text;
  text-decoration: none;

  &Active {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
