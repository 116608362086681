.container .accordion {
  box-shadow: none;
  position: relative;
  margin: 0;
  border-radius: 0;

  &:before {
    height: 0;
  }

  &Summary {
    min-height: 40px;
    height: 40px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: center;
    gap: 7px;
    padding: 0;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &Details {
    display: block;
    padding: 0 12px;
  }
}
