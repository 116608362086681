@use 'src/styles/variables/colors';

.wrapper {
  background-color: colors.$gray;
  height: 100%;
  min-width: 360px;
  display: flex;
}

.container {
  height: calc(100% - 61px);
}
