@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.sidebar {
  width: 240px;
  min-width: 240px;
  padding: 14px 8px;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  background: colors.$greyscale-900;
  position: relative;

  &.collapsed {
    width: 68px;
    min-width: 68px;
  }

  & a {
    color: colors.$sidebar-item-text;
    font-weight: typo.$bold;
  }
  & a,
  path {
    transition: color 0.2s, background-color 0.2s ease-out, fill 0.3s;
  }
}

.icon {
  path {
    fill: colors.$light-gray;
  }
}

.active {
  color: colors.$sidebar-item-active-text;
  background-color: colors.$greyscale-800;

  &:visited,
  &:link,
  &:visited {
    color: colors.$gray;
  }
}

.sidebarHeading {
  color: colors.$greyscale-500;
  font-size: 12px;
  font-weight: typo.$bolder;
  padding: 0 16px;
  min-height: 20px;
  white-space: nowrap;
  overflow: hidden;
}

.navbarDivider::after {
  height: 1px;
  width: 100%;
  background: colors.$greyscale-800;
  margin: 8px -8px;
  padding: 0 8px;
  content: '';
  display: block;
}

.logoWrapper {
  display: flex;
  flex-flow: nowrap;
  justify-content: flex-start;
  height: 40px;
  margin-bottom: 24px;
  margin-left: 12px;
  align-items: center;
  transition: 0.2s;
  gap: 7px;
  min-width: 120px;

  &.closed {
    margin-left: 8px;
  }
}

.sidebarToggle {
  position: absolute;
  right: 0;
  box-sizing: border-box;
  width: 14px;
  height: 24px;
  animation: fade-in 0.5s ease-out;
  cursor: pointer;
  background-color: colors.$greyscale-800;
  border-radius: 3px 0 0 3px;
  display: flex;
  z-index: 1;

  &.closed {
    transform: rotate(180deg);
    animation: fade-in 0.5s ease-out;
    right: -14px;
    z-index: 1002;
  }

  svg {
    margin: auto;
  }
}

.logoIcon {
  width: 114px;
  height: 35px;
  visibility: visible;
  animation: fade 0.5s;
  margin-top: 4px;
}

.hideFinancial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  overflow: hidden;
  transition: 0.2s;
  color: colors.$greyscale-400;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.hideFinancialTooltip {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
}

.financialIcon {
  width: 22px;
  height: 22px;
  background: colors.$greyscale-600;
  border-radius: 50%;
  padding: 2px;
}

.checkedFinancialIcon {
  background: linear-gradient(154.62deg, #29c58e -1.83%, #bcef30 120.8%);
}

.userInfoNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 0 18px;
  overflow: hidden;
  height: 68px;
  border-top: 1px solid colors.$greyscale-800;
  margin: 0 -8px -14px;
}

.badge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: colors.$failure;
  left: 15px;
  top: 2px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
