@use 'src/styles/variables/colors';

.member {
  border-bottom: 1px solid colors.$divider;
  background-color: colors.$white;

  .tile {
    padding: 8px 12px 6px 24px;
    height: 64px;
    display: flex;
    align-items: center;

    &Expended {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .heading {
      width: 100%;
      max-width: 212px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      &LinkIcon {
        text-decoration: none;
        cursor: pointer;
        border-radius: 50%;

        &:hover {
          background-color: colors.$black;
        }
      }

      &Avatar {
        transition: opacity 0.2s ease-out;

        &:hover {
          opacity: 0.7;
        }
      }

      &Title {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: colors.$greyscale-900;
        -webkit-text-stroke: colors.$gray;
      }

      svg {
        flex-shrink: 0;
      }

      .deleteButton {
        top: 0;
        right: 0;
        max-width: 54px;
        opacity: 0;
        text-align: right;
        position: absolute;
        display: inline-block;
        transition: opacity 0.2s ease-out;

        &.visible {
          opacity: 1;
        }
      }
    }

    .trimmed {
      max-width: 128px;
    }

    .trimmedLong {
      max-width: 200px;
    }

    .mediumTrim {
      max-width: 176px;
    }

    .chevronIcon {
      padding: 5px;
      visibility: hidden;
      margin-left: auto;

      &.visible {
        visibility: visible;
      }
    }

    &Info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      span {
        color: colors.$secondary;
        font-size: 12px;
        line-height: 16px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      p {
        color: colors.$sidebar-divider;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }

      &Projects {
        display: flex;
        align-items: center;
        color: colors.$greyscale-700;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    &:hover {
      .deleteButton {
        opacity: 1;
        transition: opacity 0.2s ease-out;
      }
    }

    .expanded {
      transform: rotate(180deg);
    }
  }
}

.iconColor {
  path {
    fill: colors.$greyscale-500;
  }
}

.specializationChip {
  display: flex;
  padding: 0 6px 2px 6px;
  margin: 4px 0;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: colors.$greyscale-300;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: colors.$greyscale-800;
}

.maxWidth60 {
  max-width: 60px;
}

.maxWidth83 {
  max-width: 83px;
}

.maxWidth134 {
  max-width: 134px;
}
