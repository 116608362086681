@use 'src/styles/variables/colors';

$whiteGradient: linear-gradient(
  90deg,
  rgba(colors.$white, 0) 0%,
  rgba(colors.$white, 1) 35%,
  rgba(colors.$white, 1) 100%
);

.stickyFooter {
  position: sticky;
  bottom: 0;
  background: colors.$white;
}

.tableData {
  border-top: 1px dashed colors.$greyscale-400;
  padding: 14px 8px;
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
}

.stickyLeft {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
  border-right: 1px solid colors.$divider;

  &WithSelection {
    background: colors.$white;
    left: 45px;
  }
}

.selectionStickyLeft {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
  background: colors.$white;
}

.stickyRight {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 6;
}

.stickyAction {
  position: sticky;
  right: 0;
  background: $whiteGradient;
}
