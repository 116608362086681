@use 'src/styles/variables/colors';
@use 'src/styles/variables/borders';

.memberLeaves {
  width: 100%;
  height: 48px;
  display: flex;
  box-sizing: content-box;
  align-items: center;
}

.leave {
  height: 28px;
  background-color: colors.$greyscale-300;
  overflow: hidden;
  cursor: pointer;
  border-radius: 6px;
  background-image: borders.$dashed-border-image;
  padding: 1px;

  &:hover {
    box-shadow: 0 4px 15px 0 colors.$shadow;
  }
}

.label {
  position: relative;
  height: 100%;
  color: colors.$greyscale-600;
  font-weight: 600;
  line-height: 16px;
}

.labelContent {
  position: absolute;
  top: 0;
  left: 0;
  min-width: max-content;
  display: flex;
  align-items: center;
  padding: 6px 4px;
  gap: 4px;
  width: 100%;
}

.icon {
  width: 16px;
  height: 16px;

  path {
    fill: colors.$greyscale-600;
  }
}
