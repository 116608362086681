@use 'src/styles/variables/colors';

$title: 136px;
$paymentDate: 174px;
$amount: 100px;
$notes: 136px;
$menu: 52px;
$tooltip: 114px;

.row {
  display: flex;
  align-items: center;
  min-width: 552px;
  height: 52px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: colors.$greyscale-200;
  }
}

.iconButton {
  width: 22px;
  height: 22px;
  padding: 1px;

  svg {
    min-width: 18px;
    min-height: 18px;
  }
}

.item {
  margin-left: 8px;
  color: colors.$greyscale-900;
  cursor: pointer;
}

.divider {
  width: 1px;
  min-width: 1px;
  height: 24px;
  margin: -4px 0;
  background-color: colors.$greyscale-300;
}

.tooltip {
  max-width: $tooltip;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  display: flex;
  align-items: center;
  flex: 1 1 $title;
}

.amount {
  display: flex;
  align-items: center;
  flex: 1 1 $amount;
}

.paymentDate {
  display: flex;
  align-items: center;
  flex: 1 1 $paymentDate;
}

.notes {
  display: flex;
  align-items: center;
  flex: 1 1 $notes;
}

.menu {
  width: $menu;
  display: flex;
  gap: 8px;
}
