@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.contentBox {
  display: flex;
  gap: 6px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: colors.$greyscale-900;

  @include for-mobile {
    flex-wrap: wrap;
  }
}

.flexWrap {
  flex-wrap: wrap;
}

.labelText {
  min-width: max-content;
  margin-right: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: colors.$greyscale-600;
}

.valueText {
  display: flex;
  padding: 4px 8px;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: colors.$greyscale-700;
  background: colors.$greyscale-200;
  border-radius: 3px;
}

.projectColor {
  margin-top: 1px;
  min-width: 14px;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  margin-right: 4px;
  display: inline-block;
}

.link:hover {
  text-decoration: none;
}

.fieldTooltipValue {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  margin-top: -2px;
  margin-bottom: -2px;
}
