@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.displayNone {
  display: none;
}

.wideDrawer {
  width: 688px;

  @include for-mobile {
    width: 360px;
  }
}
