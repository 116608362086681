@use 'src/styles/variables/colors';

.tag {
  display: flex;
  align-items: center;
  height: 18px;
  width: fit-content;
  padding: 1px 6px;
  border-radius: 4px;
}

.tagTooltip {
  max-width: 200px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-900;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.deleteButton {
  height: 14px;
  width: 14px;
  padding: 2px;
  margin-left: 2px;
}

.deleteIcon {
  height: 14px;
  min-width: 14px;
}
