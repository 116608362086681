@use 'src/styles/variables/spacing';

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-self-center {
  align-self: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.f-shrink-0 {
  flex-shrink: 0;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.align-start {
  align-items: flex-start;
}

.align-self-stretch {
  align-self: stretch;
}

@each $key, $gap in spacing.$gaps {
  .gap-#{$key} {
    gap: #{$gap};
  }
}
