@use 'src/styles/variables/colors';

.chip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  height: 16px;
  background: colors.$failure;
  border-radius: 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: colors.$white;
}
