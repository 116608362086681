@use 'src/styles/variables/colors';

.label {
  margin-top: 6px;
  text-transform: uppercase;
  color: colors.$secondary;
  font-size: 13px;
  text-align: right;
  font-weight: 700;
  display: flex;
  justify-content: right;
  align-items: center;
}
