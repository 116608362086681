@use 'src/styles/variables/colors';
@use 'src/styles/variables/borders';

$minColumnWidth: 32px;
$minQuarterColumnWidth: 12px;
$weekBlockMargin: 3px;
$daysInTimeline: 35;
$daysInCollapsedTimeline: 25;
$workingWeek: 5;
$calendarWeek: 7;
$weekendDays: 2;
$borderWidth: 1px;

.availability {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;

  &WeekBox {
    position: relative;
    font-weight: 600;
    font-size: 12px;
    min-width: $minColumnWidth * $workingWeek - $weekBlockMargin;
    line-height: 16px;
    flex: 5 1 calc((#{$workingWeek} / #{$daysInTimeline} * 100% - #{$weekBlockMargin}));
    height: 44px;
    box-sizing: border-box;
    margin: 0 2px 0 1px;

    &Mixed {
      display: flex;
      padding: 0;
      gap: 6px;
    }

    &Collapsed {
      flex-basis: calc((#{$workingWeek} / #{$daysInCollapsedTimeline} * 100% - #{$weekBlockMargin}));
    }

    &FullTime {
      background-color: colors.$accent-200;

      span {
        color: colors.$accent-700;
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: colors.$accent-700;
        }
      }
    }

    &PartTime {
      background-color: colors.$greyscale-300;

      span {
        color: colors.$greyscale-700;
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: colors.$greyscale-700;
        }
      }
    }

    &Overtime {
      background-color: colors.$overtime;

      span {
        color: colors.$red-badge-text;
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: colors.$red-badge-text;
        }
      }

      .divider {
        background-color: colors.$red-badge-text;
        opacity: 0.2;
      }
    }

    &FullLeave {
      background-color: colors.$greyscale-300;
      color: colors.$greyscale-600;
      background-image: borders.$dashed-border-image;
      box-sizing: border-box;

      span {
        color: colors.$greyscale-600;
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: colors.$greyscale-600;
        }
      }

      &Collapsed {
        flex-basis: calc(
          (#{$workingWeek} / #{$daysInCollapsedTimeline} * 100% - #{$weekBlockMargin} - #{$borderWidth} * 2)
        );
      }
    }

    &Empty {
      background-color: colors.$divider;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &QuarterView {
      min-width: calc(#{$minQuarterColumnWidth} * #{$calendarWeek} - #{$weekBlockMargin});
      flex: $calendarWeek 1;

      span {
        &:nth-child(2) {
          font-size: 10px;

          svg {
            margin: 2px 0;
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    &YearView {
      height: 40px;
      min-width: 20px;
      margin: 0 1px 0 0;
      text-align: center;
      position: relative;

      &FullLeave {
        background-color: colors.$greyscale-300;
        color: colors.$greyscale-600;
        background-image: borders.$dashed-border-image;
      }

      &PartTime {
        background-color: colors.$accent-200;

        .divider {
          background-color: colors.$dark-accent;
          opacity: 0.2;
        }
      }

      &Empty {
        background-color: colors.$divider;

        .divider {
          background-color: colors.$dark-accent;
          opacity: 0.2;
        }
      }

      svg {
        width: 12px;
        height: 12px;
      }

      &LabelWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
        padding: 2px;
      }
    }
  }

  &WeekendBox {
    flex: $weekendDays 1 $weekendDays / $daysInTimeline * 100%;
    min-width: $minColumnWidth * 2;
  }

  &Item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    min-width: $minColumnWidth;
    line-height: 16px;

    &Unavailable {
      position: relative;

      &::after {
        z-index: -1;
        content: '';
        position: absolute;
        left: 1px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - #{$weekBlockMargin});
        padding-bottom: calc(100% + 4px);
        text-align: center;
        line-height: 24px;
      }

      &Collapsed {
        &::after {
          padding-bottom: calc(100% - 5px);
        }
      }

      &PartTime {
        &More {
          color: colors.$accent-600;

          &::after {
            background-color: colors.$accent-100;
          }
        }

        &Less {
          color: colors.$accent-600;

          &::after {
            background-color: colors.$accent-200;
          }
        }
      }

      &FullTime {
        color: colors.$greyscale-700;
        &::after {
          background-color: colors.$divider;
        }
      }

      &Overtime {
        color: colors.$red-badge-text;

        &::after {
          background-color: colors.$overtime;
        }
      }

      &Leave {
        &::after {
          z-index: -1;
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
          background-color: colors.$greyscale-400;
          border-radius: 4px;
          max-width: calc(100% - 4px);
          padding-bottom: calc(100% + 2px);
          text-align: center;
          line-height: 24px;
          background-image: borders.$dashed-border-image;
          box-sizing: border-box;
        }

        &Collapsed {
          &::after {
            padding-bottom: calc(100% - 7px);
          }
        }

        &Clickable {
          cursor: pointer;
        }
      }
    }
  }
}

.weeklyCellTooltip {
  width: 100%;
  height: 100%;
  padding: 8px 7px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &Content {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    svg {
      margin-left: 2px;
      path {
        fill: colors.$white;
      }
    }
  }
}

.progressBar {
  width: 100%;
  min-height: 4px;
  border-radius: 100px;
  background-color: colors.$accent-100;

  &Empty {
    background-color: colors.$white;
  }

  &FullTime {
    background-color: colors.$accent-400;
  }

  &PartTime {
    background-color: colors.$accent-400;
  }

  &Overtime {
    background-color: colors.$failure;
  }

  &FullLeave {
    background-color: colors.$greyscale-400;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: colors.$greyscale-400;
  margin: 2px 0 4px;
}
