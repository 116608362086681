@use 'src/styles/variables/colors';

.container {
  margin-top: 26px;
}

.dragIcon {
  margin-left: auto;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 14px;
}

.box {
  margin-top: 19px;
  margin-bottom: 16px;
}

.button {
  height: 24px;
  width: 24px;
  margin-left: 16px;

  & .icon {
    min-width: 20px;
    height: 20px;
    path {
      fill: colors.$secondary;
    }
  }
}
