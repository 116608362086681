@use 'src/styles/variables/colors';

.container {
  width: 376px;
  min-height: 400px;
  height: auto;
  padding: 12px;
}

.box {
  max-height: 264px;
  overflow-y: auto;
  margin: 0 -12px;
  padding: 0 12px;
}

.button {
  height: 34px;
  font-size: 14px;
  font-weight: 600;
}

.icon {
  &.open {
    transform: rotate(-180deg);
  }

  &.disabled {
    opacity: 0.4;
  }
}

.listTitle {
  padding-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid colors.$greyscale-300;
  color: colors.$greyscale-900;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.projectCell {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 0;
}

.memberDate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.projectItem {
  height: 54px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$greyscale-900;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.projectOpacity {
  opacity: 0.4;
}
