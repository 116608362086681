@use 'src/styles/variables/colors';

.dialog {
  min-width: 800px;
}

.dialogContent {
  padding: 24px 0;
}

.dialogTitle {
  display: flex;
  width: 700px;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 450px;
  max-height: 450px;
  padding: 0 24px;

  .tableBox {
    overflow-x: hidden;

    td:nth-child(-n + 2),
    th:nth-child(-n + 2) {
      border-right: 1px solid colors.$greyscale-300;
    }

    td:first-child,
    th:first-child {
      max-width: 262px;
      min-width: 262px;
    }
  }
}

.activityLogIcon {
  path {
    fill: colors.$greyscale-500;
  }
}

.accrualMonth {
  margin-right: 114px;

  > div {
    width: min-content;
  }
}

.userTitle {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.balanceHeader {
  display: flex;
  justify-content: center;
  width: 132px;
}

.settingButton {
  height: 28px;
  padding: 6px;
  font-size: 12px;
}

.settingIcon {
  width: 20px;
  height: 20px;
  margin-right: -4px;

  path {
    fill: colors.$greyscale-600;
  }
}

.shown {
  opacity: 1;
}

.hidden {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.balance {
  font-weight: 600;
  font-size: 14px;
}

.mainColor {
  color: colors.$greyscale-900;
}

.secondaryColor {
  color: colors.$greyscale-600;
}
