@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.label {
  color: #334a57;
}

.container {
  border: 1px solid colors.$light-gray;
  margin-top: 4px;

  .options {
    min-width: 200px;
    padding: 8px 0;

    @include for-mobile {
      min-width: 136px;
    }
  }

  .option {
    padding: 6px 12px 6px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background-color: colors.$divider;
    }
  }

  .divider {
    height: 1px;
    background-color: colors.$divider;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.calendar {
  border-left: 1px solid colors.$light-gray;
}

.rightArrow {
  transform: rotate(0deg) scale(-1);
}

.iconButton {
  margin-left: 4px;
  padding: 8px;
  border-radius: 4px;
}
