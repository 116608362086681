@use 'src/styles/variables/colors';

$seniorityFlexBasis: 100px;
$dateFlexBasis: 125px;
$rateFlexBasis: 150px;
$menuFlexBasis: 50px;
$grayGradient: linear-gradient(
  90deg,
  rgba(colors.$greyscale-200, 0) 0%,
  rgba(colors.$greyscale-200, 1) 35%,
  rgba(colors.$greyscale-200, 1) 100%
);

.row {
  display: flex;
  padding: 18px 9px 18px 36px;
  gap: 4px;
  position: relative;

  &:hover {
    background: colors.$greyscale-200;
  }

  .menu {
    position: relative;
    visibility: hidden;
  }

  &:hover {
    .menu {
      visibility: visible;
    }
  }
}

.item {
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: colors.$greyscale-900;
  cursor: pointer;
}

.iconButton {
  width: 22px;
  height: 22px;
  padding: 1px;

  svg {
    min-width: 18px;
    min-height: 18px;
  }
}

.addIcon {
  position: absolute;
  left: -30px;

  path {
    fill: colors.$icon-button;
  }
}

.hidden {
  visibility: hidden;
}

.seniority {
  padding-left: 1px;
  flex: 2 1 $seniorityFlexBasis;
}

.date {
  flex: 1 1 $dateFlexBasis;
}

.startDate {
  padding-left: 3px;
}

.endDate {
  position: relative;
  padding-left: 2px;
}

.menu {
  flex: 0 1 $menuFlexBasis;
  display: flex;
  gap: 6px;
  height: 16px;
  align-items: center;
  justify-content: flex-end;
}

.rate {
  flex: 3 1 $rateFlexBasis;
  padding-left: 6px;
}

.currency {
  min-width: 12px;
  margin-right: 4px;
}

.amount {
  margin-right: 2px;
  width: 58%;
}

.unit {
  width: 35%;
}

.borderBottom {
  position: absolute;
  bottom: -1px;
  left: 8px;
  right: 8px;
  height: 1px;
  background-color: colors.$greyscale-300;
}

.divider {
  width: 1px;
  height: 24px;
  margin: -4px 0;
  background-color: colors.$greyscale-300;
}
