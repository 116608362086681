@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.emptyState {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &Label {
    font-size: 18px;
    font-weight: typo.$bold;
    color: colors.$greyscale-500;
  }
}
