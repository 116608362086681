@use 'src/styles/variables/colors';

.userCellButton {
  padding: 8px;
  background-color: transparent;
  font-size: 14px;
  color: colors.$greyscale-600;
  font-weight: 700;

  &:hover {
    background-color: colors.$divider;
  }
}

.userCellButtonBorder {
  border-color: colors.$white;

  &:hover {
    background-color: colors.$gray;
    border-color: colors.$gray;
  }
}

.buttonArrow {
  margin: 0;
  transition: transform 0.1s;

  path {
    fill: colors.$greyscale-600;
  }

  &.open {
    transform: rotate(180deg);
  }
}

.menu {
  max-height: 500px;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  height: 48px;
  gap: 16px;
  padding: 4px 12px;
  cursor: auto;
}

.showAllMenuItem {
  position: sticky;
  bottom: 0;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 700;
  color: colors.$greyscale-600;
  background-color: colors.$white;
}

.hideIcon {
  width: 20px;
  height: 20px;

  path {
    fill: colors.$greyscale-600;
  }
}

.userTitle {
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttonIcon {
  path {
    fill: colors.$greyscale-500;
  }
}

.emptyMessage {
  min-height: 40px;
  align-items: center;
  padding: 16px;
}
