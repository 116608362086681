@use 'src/styles/variables/typo';

.paper {
  border-radius: 6px;
}

.header {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 24px;
}

.title {
  padding: 0;
  font-size: 18px;

  h2 {
    font-weight: 600;
  }
}

.actions {
  padding: 18px 24px;
  text-align: right;
}
