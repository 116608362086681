@use 'src/styles/variables/colors';

.box {
  display: flex;
  padding: 12px 16px;
  gap: 10px;
  margin-bottom: 16px;
  background: colors.$greyscale-200;
  border: 1px solid colors.$greyscale-400;
  border-radius: 2px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-900;
}

.title {
  font-weight: 700;
}

.title {
  font-weight: 700;
}

.infoIcon {
  min-width: 16px;
}
