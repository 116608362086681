@use 'variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  width: auto;
  max-width: 500px;

  @include for-mobile {
    width: 100%;
  }
}

.toast {
  display: flex;
  align-items: center;
  margin: 0 0 6px 0;
  border-radius: 6px;
  background: rgba(31, 44, 52, 0.95);
  min-height: 48px;
  padding: 0 6px 0 2px;

  @include for-mobile {
    margin: 8px;
  }

  button {
    position: initial;
    min-height: inherit;
    padding: 0 6px;
    opacity: 1;
    color: colors.$white;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.toastBody {
  font-size: 14px;
  font-weight: 600;
  font-family: Manrope, 'sans-serif';
  line-height: 20px;
  color: colors.$white;

  & > :first-child {
    width: 32px;
    height: 32px;
  }
}
