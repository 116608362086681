@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  @include for-mobile {
    overflow-y: auto;
  }
}

.button {
  height: 32px;
  margin: 4px 0 16px 0;
  font-weight: 600;
  color: colors.$greyscale-600;
  font-size: 16px;
  line-height: 24px;

  & .icon {
    width: 20px;
    height: 20px;
    margin-right: -4px;
    path {
      fill: colors.$greyscale-600;
    }
  }
}

.listBox {
  max-height: 250px;
  overflow-y: auto;
  margin: 0 -16px;
  padding: 0 16px;

  @include for-mobile {
    overflow-y: initial;
  }
}

.endListAnchor {
  margin-bottom: 60px;
}

.additionalTitle {
  max-width: 108px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
