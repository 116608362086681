@use 'src/styles/variables/colors';

.dialog {
  width: 560px;

  &Content {
    min-height: 120px;
    overflow: hidden;
    padding: 0;
  }
}

.box {
  padding: 16px 24px 24px;

  .noteTextarea {
    > div {
      height: 170px;
    }
  }
}

.tooltip {
  width: 36px;
  height: 36px;
  padding: 8px;
}

.openButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 36px;
  height: 36px;
  padding: 0;
  z-index: 900;
  background-color: colors.$greyscale-900;

  &:hover {
    background-color: colors.$black;
  }
}

.description {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 22px;
  color: colors.$greyscale-600;
}

.select {
  width: 100%;
  height: min-content;
  margin-bottom: 24px;

  & > * {
    font-weight: 600;
    font-size: 14px;
  }
}

.dropZone {
  position: relative;

  path {
    fill: colors.$greyscale-600;
  }
}

.fileInput {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;
}

.fileList {
  margin-top: 24px;
  max-height: 200px;
  overflow-y: auto;
}
