@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  margin-bottom: 16px;

  &Table {
    display: flex;
    overflow-y: auto;
  }

  &Title {
    max-width: 260px;
    font-size: 14px;
    margin-left: 8px;
    font-weight: typo.$bold;
    color: colors.$typo-primary;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Chip {
    min-width: 12px;
    padding: 0 2px;
    height: 16px;
    border-radius: 2px;
    background-color: colors.$light-gray;
    color: colors.$typo-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 8px;
  }

  &ProjectCell {
    width: 180px;

    @include for-mobile {
      max-width: 148px;
    }
  }

  &ProjectTitle {
    max-width: 132px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include for-mobile {
      max-width: 120px;
    }
  }
}

.emptyState {
  margin-top: 104px;
}

.projectManagerTitle {
  max-width: 240px;
}

.settingTooltip {
  height: 24px;
}

.settingIcon {
  path {
    fill: colors.$greyscale-600;
  }
}

.forecastedMargin {
  width: 125px;
}
