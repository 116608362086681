@use 'src/styles/variables/colors';

.container {
  min-width: max-content;
  display: flex;
  align-items: flex-end;
}

.largeInteger {
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
}

.mediumInteger {
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
}

.smallInteger {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}

.largeDecimal {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.mediumDecimal {
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;
}

.smallDecimal {
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
}

.largeSuffix {
  color: colors.$greyscale-500;
  margin-left: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.mediumSuffix {
  color: colors.$greyscale-500;
  margin-left: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 25px;
}

.smallSuffix {
  color: colors.$greyscale-500;
  margin-left: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
}

.smallPrefix {
  color: colors.$greyscale-500;
  margin-right: 2px;
  margin-bottom: auto;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
}

.mediumPrefix {
  color: colors.$greyscale-500;
  margin-right: 2px;
  margin-bottom: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
}

.largePrefix {
  margin-bottom: auto;
  color: colors.$greyscale-900;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}
