@use 'src/styles/variables/colors';

.badgeWrapper {
  border-radius: 3px;
  max-height: 20px;
  padding: 4px 6px;
  font-size: 1.3rem;
  white-space: nowrap;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
  }

  &.purple {
    background: colors.$purple-badge;
    color: colors.$purple-text;
  }

  &.orange {
    background: colors.$yellow-light;
    color: colors.$yellow-brown;

    .dot {
      background: colors.$orange;
    }
  }

  &.pink {
    background: colors.$pink-badge;
    color: colors.$pink-text;
  }

  &.green {
    background: colors.$green-gray;
    color: colors.$green-gray-text;

    .dot {
      background: colors.$dark-gray;
    }
  }

  &.navy {
    background: colors.$blue-light;
    color: colors.$blue-dark;

    .dot {
      background: colors.$dark-gray;
    }
  }

  &.emerald {
    background: colors.$green-light;
    color: colors.$green-dark-text;

    .dot {
      background: colors.$green;
    }
  }

  &.blue {
    background: colors.$blue-badge;
    color: colors.$blue-badge-text;
  }

  &.yellow {
    background: colors.$yellow-badge;
    color: colors.$yellow-badge-text;

    .dot {
      background: colors.$yellow;
    }
  }

  &.red {
    background: colors.$red-light;
    color: colors.$red-badge-text;
  }

  &.accent {
    background: colors.$purple-white;
    color: colors.$accent-text;
  }

  &.gray {
    background: colors.$divider;
    color: colors.$typo-primary;
  }

  &.greyscale {
    background: colors.$greyscale-200;
    color: colors.$greyscale-700;
  }

  &.success {
    background: colors.$green-transparent;
    color: colors.$green-text;
  }

  &.failure {
    background: colors.$red-light;
    color: colors.$red-dark;
  }
}
