@use 'src/styles/variables/colors';

$sidebarWidth: 185px;

.chart {
  display: flex;
  min-height: 216px;
  width: 100%;
  margin: 32px 0 24px;
  padding: 0 30px;
}

.chartSidebar {
  display: inline-block;
}

.chartContainer {
  height: 216px;
  width: calc(100% - #{$sidebarWidth});
  display: inline-block;
}
