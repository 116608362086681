@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.box {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid colors.$divider;
  padding: 12px 12px 12px 24px;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  background-color: colors.$white;
  z-index: 100;
}
.title {
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
}

.drawer {
  border-radius: 0;

  @include for-mobile {
    width: 370px;
  }
}
