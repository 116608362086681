@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.assignmentCell {
  &Button {
    height: 34px;
    background-color: transparent;
    font-size: 14px;
    font-weight: typo.$bolder;
    color: colors.$typo-primary;

    &:hover {
      background-color: colors.$divider;
    }

    &.disabled {
      background-color: transparent;
    }
  }

  &Icon {
    width: 16px;
    height: 16px;
    color: colors.$typo-primary;

    & path {
      fill: colors.$greyscale-500;
    }
  }
}

.avatar {
  min-width: 24px;
  margin-right: 8px;
}

.title {
  font-size: 14px;
  font-weight: typo.$bold;
  color: colors.$typo-primary;
}

.subtitle {
  font-size: 13px;
  font-weight: typo.$bold;
  color: colors.$secondary;
}

.projectColor {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
  display: inline-block;
}
