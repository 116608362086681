@use 'src/styles/variables/colors';

.avatar {
  border-radius: 50%;
  position: relative;
  color: colors.$white;

  &.xs {
    min-width: 16px;
    width: 16px;
    height: 16px;
    font-size: 6px;
  }

  &.sm {
    min-width: 24px;
    width: 24px;
    height: 24px;
    font-size: 10px;
  }

  &.md {
    min-width: 28px;
    width: 28px;
    height: 28px;
    font-size: 12px;
  }

  &.lg {
    min-width: 32px;
    width: 32px;
    height: 32px;
    font-size: 14px;
  }

  &.xl {
    min-width: 48px;
    width: 48px;
    height: 48px;
    font-size: 18px;
  }
}

.indicator {
  position: absolute;
  right: -3px;
  bottom: -3px;
  border-radius: 3px;
  background-color: colors.$white;
  width: 50%;
  height: 50%;
  padding: 2px;

  & > div {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: colors.$accent;
    padding: 3px;

    & > div {
      width: 100%;
      height: 100%;
      background-color: colors.$white;
      border-radius: 0.5px;
    }
  }
}
