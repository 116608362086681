@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: colors.$sidebar-divider;
  border-radius: 4px;
  font-size: 12px;
  font-weight: typo.$bold;
  margin-right: 7px;
  border: 1px solid colors.$light-gray;

  &.selected {
    color: colors.$white;
    background: colors.$typo-primary;
    border-color: colors.$typo-primary;
  }

  &:last-child {
    margin-right: 0;
  }
}

.editable {
  &:hover {
    cursor: pointer;
    border-color: colors.$greyscale-500;
  }
}
