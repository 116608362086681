@use 'src/styles/variables/colors';

$headingHeight: 53px;
$headingNavigationHeight: 29px;
$filtersHeight: 120px;
$searchCellHeight: 50px;
$collapsedSidebarWidth: 68px;
$memberRoleCellHeight: 36px;

.timeline {
  position: relative;
  width: 100%;
  height: calc(100% - #{$headingHeight + $headingNavigationHeight + 1px});
  overflow: auto;

  &Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
  }

  &Row {
    display: flex;
    background-color: colors.$white;

    &MemberProject {
      height: calc(48px * var(--assignments, 1));
      line-height: calc(48px * var(--assignments, 1));
      display: flex;
      padding: 14px 12px 14px 27px;
      justify-content: flex-start;
      width: 100%;

      &Logo {
        margin-top: 2px;
        min-width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: colors.$greyscale-500;
        margin-right: 14px;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: colors.$typo-primary;
      }
    }
    &MemberLeaves {
      display: flex;
      align-items: center;
      padding: 14px 12px 14px 27px;
      height: 48px;

      &IconWrapper {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid colors.$greyscale-200;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: colors.$typo-primary;
      }
    }
  }

  &Availability {
    z-index: 2;
    width: 100%;
  }

  &Sidebar {
    position: sticky;
    left: 0;
    z-index: 3;
    width: 315px;
    flex-shrink: 0;
    border-right: 1px solid colors.$divider;
    background-color: colors.$white;

    &Tile {
      max-height: 56px;
    }

    &Button {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: flex-start;
      padding-left: 27px;
      font-weight: 600;
      color: colors.$secondary;

      &Icon {
        path {
          fill: colors.$secondary;
        }
      }
    }
  }

  &Bar {
    line-height: 40px;
  }

  &Empty {
    height: 100%;
  }

  &Loader {
    position: fixed;
    width: 100%;
    height: calc(100vh - 232px);
    z-index: 13;
  }

  &LoadingState {
    position: fixed;
    width: 100%;
    height: calc(100vh - 232px);
    z-index: 13;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: colors.$greyscale-900;

    :last-child {
      color: colors.$greyscale-600;
    }
  }

  &LoadingExpandedSidebar {
    width: calc(100% - 240px);
  }

  &Container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }
}

.countCell {
  margin-top: -$searchCellHeight;
  width: 315px;
  height: $searchCellHeight;
  max-height: $searchCellHeight;
  position: sticky;
  left: 0;
  top: $filtersHeight;
  z-index: 5;
  display: flex;
  align-items: center;
  background-color: colors.$white;
  border-right: 1px solid colors.$divider;
  border-bottom: 1px solid colors.$divider;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: colors.$greyscale-600;
  text-transform: lowercase;
}

.timelineContainer .timelineAccordion {
  box-shadow: none;
  position: relative;
  margin: 0;

  &Summary {
    position: sticky;
    top: $filtersHeight + $searchCellHeight;
    left: 0;
    width: 315px;
    min-height: 36px;
    height: 36px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    gap: 5px;
    z-index: 4;
    background-color: colors.$greyscale-200;
    border-right: 1px solid colors.$divider;
    border-bottom: 1px solid colors.$divider;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &Text {
      color: colors.$greyscale-700;
    }

    &Count {
      align-items: center;
      margin-left: 16px;
      color: colors.$greyscale-600;
    }
  }

  &Details {
    display: block;
    padding: 0;
  }
}

.blankTimelineRow {
  width: 100%;
  height: $memberRoleCellHeight;
}

.projectsSection {
  display: flex;
  flex-direction: column;
}

.spinnerBox {
  height: calc(100vh - 330px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes minimize {
  0% {
    max-height: 400px;
    min-height: 56px;
    overflow: hidden;
  }
  100% {
    max-height: 0;
    min-height: 0;
    overflow: hidden;
  }
}

.hiddenMember {
  animation: minimize 0.5s forwards;
}

.memberAccordionTitle {
  max-width: 174px;
  letter-spacing: 0.5px;
}
