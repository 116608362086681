@use 'src/styles/variables/colors';

$title: 278px;
$effectiveFrom: 278px;

.header {
  display: flex;
  gap: 8px;
  height: 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: colors.$greyscale-600;
  text-transform: uppercase;
}

.title {
  display: flex;
  align-items: center;
  width: $title;
  padding-left: 16px;
}

.effectiveFrom {
  display: flex;
  align-items: center;
  width: $effectiveFrom;
  padding-left: 8px;
}
