@use 'src/styles/variables/colors';

.wrapper {
  background: colors.$gray;
  height: 100%;
  width: 100%;
  min-height: 700px;
}

.companyLogo {
  position: absolute;
  top: 30px;
  height: auto;
  width: 140px;
}
