@use 'src/styles/variables/colors';

.actionButton {
  cursor: pointer;
  border: 1px solid colors.$dark-gray;
  border-radius: 14px;
  &:hover {
    border: 1px solid colors.$primary;
    fill: colors.$primary;
    background: colors.$gray;

    & path {
      fill: colors.$primary;
    }
  }
}
