@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.box {
  padding: 12px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  @include for-mobile {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px;
    padding: 12px 8px;
  }
}

.count {
  margin-right: auto;
  color: colors.$greyscale-600;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
