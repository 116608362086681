@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.requestInfo {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  margin-top: 16px;
  gap: 16px;
  background-color: colors.$greyscale-200;
  border: 1px solid colors.$greyscale-400;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-800;
}

.lockIcon {
  min-width: 20px;
  height: 20px;
}

.checkboxLabel {
  margin: 0 0 24px;

  .checkboxLabelText {
    color: colors.$secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .active {
    color: colors.$greyscale-900;
  }

  .disable {
    color: colors.$dark-gray;
  }
}

.twoItemsBox {
  display: flex;
  margin-bottom: 16px;
  gap: 48px;

  @include for-mobile {
    flex-direction: column;
    gap: 24px;
  }
}

.datePickersDash {
  padding: 36px 16px 0;

  @include for-mobile {
    padding: 36px 2px 0;
  }
}
