@use 'src/styles/variables/colors';

.form {
  height: auto;
  margin-bottom: 20px;
}

.autocompletesContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid colors.$dark-gray;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 36px;
  font-weight: 600;
  font-size: 14px;
  background-color: colors.$white;

  &:not(.error):hover {
    border-color: colors.$greyscale-900;
  }
  &:not(.error):focus,
  &:not(.error):active,
  &:not(.error):visited,
  &:not(.error):target,
  &:not(.error):focus-visible,
  &:not(.error):focus-within {
    border-color: colors.$greyscale-900;
  }
  fieldset {
    border: none;
    border-radius: 0;
  }
  :global(.MuiAutocomplete-endAdornment) {
    top: auto;
  }

  .projectAutocomplete {
    width: 100%;

    & > * {
      & > * {
        padding-right: 106px !important;
      }
    }
  }

  &.error {
    border: 1px solid colors.$error;
  }
}

.accessMenu {
  position: absolute;
  right: 0;
}

.memberOptionSubTitle {
  color: colors.$greyscale-600;
  font-size: 13px;
  line-height: 16px;
}

.tag {
  box-sizing: border-box;
  height: 24px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-400;
  margin: 2px;
}

.tagTooltip {
  max-width: 150px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: colors.$greyscale-700;
}

.checkboxLabelText {
  color: colors.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.active {
  color: colors.$greyscale-900;
}
