@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';
@use 'src/styles/variables/mediaQuery' as *;

.title {
  max-width: 300px;
  font-size: 22px;
  font-weight: 600;
  color: colors.$greyscale-900;

  @include for-mobile {
    max-width: 260px;
  }
}

.jobTitle {
  font-size: 12px;
  font-weight: 700;
  color: colors.$greyscale-500;

  @include for-mobile {
    width: max-content;
  }
}

.mailTo,
.mailTo:hover,
.mailTo:link,
.mailTo:visited {
  text-decoration: underline;
}

.divider {
  height: 1.7rem;
  width: 1px;
  background-color: colors.$greyscale-500;
  margin: 0 16px;
}
