@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.project {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  max-width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include for-mobile {
    max-width: 100px;
  }
}

.role {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: colors.$secondary;
}

.project,
.role {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include for-mobile {
    max-width: 100px;
  }
}

.projectColor {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-block;
}
