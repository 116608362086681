// semantic
$primary: #433db3;
$primary-hover: #cfceee;
$secondary: #5c6e79;
$typo-primary: #24343d;
$typo-secondary: $secondary;

$accent-100: #effbf0;
$accent-200: #cff2d3;
$accent-300: #a0e6a7;
$accent-400: #60d56c;
$accent-600: #1d4020;
$accent-700: #0a150b;

$complementary-500: #374c1e;

$gray: #f9fafa;
$light-gray: #d6dbdd;
$dark-gray: #dbe6eb;
$greyscale-200: #f5f6f7;
$greyscale-300: #ebedee;
$greyscale-350: #e0e3e5;
$greyscale-400: #d6dbdd;
$greyscale-500: #85929a;
$greyscale-550: #708089;
$greyscale-600: #5c6e79;
$greyscale-700: #334a57;
$greyscale-800: #1f2c34;
$greyscale-900: #050709;
$transparent-gray: #c4c4c430;
$menu-divider: #dce5e8;
$shadow: #00000033;
// atomic
$orange: #f2825a;
$orange-dark: #ee5f2b;
$orange-light: #fbdbd0;
// yellow should be changed
$yellow: #f6bc00;
$yellow-400: #ebbc34;
$yellow-button: #fff6dba3;
$yellow-border: #ffe8a5;
$yellow-light: #fff1e5;
$yellow-badge: #fff6db;
$yellow-badge-border: #4c3d111a;
$yellow-badge-text: #4c3d11;
$yellow-brown: #301f11;
$green: #3be8c9;
$green-light: #e3eed2;
$green-dark-text: #37402b;
$green-gray: #edf2f2;
$green-gray-text: #1b2a2a;
$green-transparent: #d7f7f1;
$green-text: #00866c;
$green-dark: #009c7d;
$purple: #615cc7;
$purple-badge: #f5ebfd;
$purple-text: #3b284c;
$purple-dark: #312e64;
$purple-light: #d0ceee;
$purple-white: #efeff9;
$accent-text: #131228;
$pink-badge: #fde3f9;
$pink-text: #31202f;
$blue-light: #dde6f8;
$blue: #335fb8;
$blue-dark: #192742;
$blue-badge: #e9f5f8;
$blue-badge-text: #22434a;
$white: white;
$black: black;
$red: #ee5f2b;
$red-light: #ffe3d9;
$red-badge-text: #471c0d;
$red-dark: #c44618;
$delete: #f7927e;
$failure: #f7735b;
$overtime: #ffaf91;
$delete-hover: #f1411e;
$error: #f44336;
$remove-icon-hover: #f75839;
$edit-icon-hover: #334a57;
$accent: #60d56c;
$accent-500: #43954c;
$dark-accent: #1d4020;

$icon-button: #587e94;
$button-icon-hover: #ffffff1f;
$button-border: rgba(92, 110, 121, 0.5);
$button-hover: rgba(5, 7, 9, 0.04);
$box-shadow: rgba(0, 0, 0, 0.15);
$divider: #ebedee;
$sidebar-bg: #141e23;
$sidebar-item-text: #c2c9cd;
$sidebar-item-active-text: $gray;
$sidebar-item-active-bg: $typo-primary;
$sidebar-divider: #334a57;

$unauth-bg-gradient: radial-gradient(50% 50% at 50% 50%, #e5f0f6 0%, #f7fbfe 100%);
