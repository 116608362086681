@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.userCellButton {
  height: 34px;
  background-color: transparent;
  font-size: 14px;
  font-weight: typo.$bolder;
  color: colors.$typo-primary;

  &:hover {
    background-color: colors.$divider;
  }

  &.disabled {
    background-color: transparent;
  }
}

.tag {
  box-sizing: border-box;
  min-height: 24px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid colors.$greyscale-400;
}

.emptyMessage {
  min-height: 40px;
  align-items: center;
  padding: 16px;
}

.title {
  padding: 4px 16px 6px;
  color: colors.$greyscale-600;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}
