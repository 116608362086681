@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.endDateReminder {
  width: fit-content;

  &Color {
    width: 6px;
    height: 6px;
    border-radius: 2px;
    margin-right: 4px;
    display: inline-block;
    background-color: colors.$red;

    &.active {
      background-color: colors.$green-dark;
    }
  }

  &Title {
    font-size: 12px;
    margin-right: 8px;
    color: colors.$typo-primary;
  }

  &Badge {
    padding: 2px 4px;
    font-size: 12px;
    color: colors.$white;
    font-weight: typo.$bold;
    border-radius: 2px;
    background-color: colors.$red;
  }
}
