@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.inputIcon {
  path {
    fill: colors.$greyscale-500;
  }
}

.box {
  display: grid;
  grid-template-columns: 100% 0;
  transition: all 0.2s ease-out;
  overflow: hidden;
}

.boxFocused {
  display: grid;
  grid-template-columns: 80% 20%;
  transition: all 0.2s ease-out;
}

.button {
  visibility: initial;
  transition: all 0.2s ease-out;
}

.hiddenButton {
  visibility: hidden;
  max-width: 0;
  min-width: 0;
  padding: 0;
  transition: all 0.2s ease-out;
}
