@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.employmentTypeLabel {
  padding: 6px;
  border-radius: 4px;
  font-weight: 600;
  line-height: 80%;
  font-size: 13px;
  text-transform: capitalize;
  background-color: colors.$greyscale-200;
}

.employmentTypeLabelContractor {
  background-color: colors.$green-gray;
}

.userTitle {
  max-width: 180px;

  @include for-mobile {
    max-width: 120px;
  }
}

.tooltipText {
  max-width: 200px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settingTooltip {
  height: 24px;
}

.settingIcon {
  path {
    fill: colors.$greyscale-600;
  }
}
