@use 'src/styles/variables/colors';
@use 'src/styles/variables/fonts';
@use 'src/styles/variables/mediaQuery' as *;

:global(.react-datepicker-popper) {
  z-index: 100;
}

.datepicker {
  &Container {
    &.detached {
      box-shadow: 0 4px 15px colors.$box-shadow;
      background-color: colors.$white;
      border: 1px solid colors.$light-gray;
      border-radius: 4px;
    }

    display: flex;
    padding: 12px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 12px;
    color: colors.$greyscale-900;
    font-family: fonts.$primary-font;

    @include for-mobile {
      padding: 12px 0;
      flex-wrap: wrap;
      width: 204px;
    }

    :global(.react-datepicker__header) {
      padding-top: 0;
      background-color: transparent;
      border: 0;
    }

    :global(.react-datepicker__day) {
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      margin: 0;
      border-radius: 4px;

      &:hover {
        background: colors.$divider;
      }
    }

    :global(.react-datepicker__day--today) {
      box-shadow: inset 0 0 0 1px colors.$greyscale-900;
      background-color: transparent;
      color: colors.$greyscale-900;
    }

    :global(.react-datepicker__day--selected) {
      background-color: colors.$greyscale-900;
      color: colors.$white;

      &:hover {
        background-color: colors.$greyscale-900;
      }
    }

    :global(.react-datepicker__day--keyboard-selected) {
      background-color: transparent;
      color: colors.$greyscale-900;
    }

    :global(.react-datepicker__day--outside-month) {
      color: colors.$greyscale-700;
    }
    :global(.react-datepicker__day--selected.react-datepicker__day--outside-month) {
      color: colors.$white;
    }

    :global(.react-datepicker__day--outside-month:empty) {
      color: colors.$greyscale-900;
      height: 0;
    }

    :global(.react-datepicker__month-text) {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      font-family: fonts.$primary-font;
      color: colors.$greyscale-900;
      text-transform: uppercase;
      width: 48px;
      margin: 0;
      &:hover {
        background: colors.$divider;
      }
    }

    :global(.react-datepicker__month-text--today) {
      box-shadow: inset 0 0 0 1px colors.$greyscale-900;
      background-color: transparent;
    }

    :global(.react-datepicker__day--in-range),
    :global(.react-datepicker__day--in-selecting-range) {
      background-color: colors.$greyscale-200;
      border-radius: 0;
      color: colors.$greyscale-900;
    }

    :global(.react-datepicker__month--in-range) {
      background-color: colors.$greyscale-200;
      border-radius: 0;
    }

    :global(.react-datepicker__day--selecting-range-start),
    :global(.react-datepicker__day--range-start),
    :global(.react-datepicker__month--range-start) {
      background-color: colors.$greyscale-900;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: colors.$white;
    }

    :global(.react-datepicker__day--selecting-range-end),
    :global(.react-datepicker__day--range-end),
    :global(.react-datepicker__month--range-end) {
      background-color: colors.$greyscale-900;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: colors.$white;
      &:hover {
        background-color: colors.$greyscale-900;
      }
    }

    :global(.react-datepicker__day-name) {
      width: 28px;
      height: 28px;
      line-height: 28px;
      margin: 0;
      font-family: fonts.$primary-font;
      font-weight: 600;
      font-size: 12px;
      color: colors.$greyscale-900;
      text-transform: uppercase;
    }

    :global(.react-datepicker__month-text--keyboard-selected) {
      background-color: transparent;
    }

    :global(.react-datepicker__month--selected) {
      background-color: colors.$greyscale-900;
      color: colors.$white;
      &:hover {
        background-color: colors.$greyscale-900;
      }
    }

    &.monthView {
      width: max-content;

      @include for-mobile {
        width: 204px;
      }

      .yearHeader {
        color: inherit;
      }
    }
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .yearHeader {
      font-family: fonts.$primary-font;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
    }

    &Button {
      min-width: auto;
      width: 28px;

      &:hover {
        background-color: colors.$divider;
      }

      svg {
        path {
          fill: colors.$greyscale-900;
        }
      }

      &Right {
        transform: rotate(180deg);
      }
    }
  }
}
