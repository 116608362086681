@use 'src/styles/variables/colors';

.button {
  padding: 0;
  height: 34px;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

.popper {
  z-index: 1002;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 15px 0 #00000026;
}

.content {
  background-color: white;
}
