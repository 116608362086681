@use 'src/styles/variables/colors';

.dragItem {
  display: flex;
  height: 40px;
  padding: 8px 8px 8px 16px;
  align-items: center;
  background-color: colors.$white;
  border: 1px solid colors.$greyscale-300;
  border-radius: 4px;
  margin-bottom: 16px;

  .disabledCheckbox {
    color: colors.$greyscale-900;
  }
}

.disabled {
  background-color: colors.$gray;
}

.dragging {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.checkboxLabelText {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: colors.$greyscale-600;
}

.active {
  color: colors.$greyscale-900;
}

.dragIcon {
  margin-left: auto;

  path {
    fill: colors.$greyscale-400;
  }
}

.activeDragIcon {
  path {
    fill: colors.$greyscale-600;
  }
}
