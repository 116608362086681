@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.twoItemsBox {
  display: flex;
  gap: 48px;

  @include for-mobile {
    flex-direction: column;
    gap: 0;
  }
}
