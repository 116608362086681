@use 'src/styles/variables/colors';

.switchLabel {
  font-size: 1.2rem;
  margin-right: 8px;
  font-weight: 500;
  line-height: 16px;
}

.userTitle {
  max-width: 200px;
}

.email {
  max-width: 200px;
  padding: 2px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: colors.$button-hover;
  }
}

.createButton {
  margin-right: auto;
}

.badge {
  margin: 3px 0;
}
