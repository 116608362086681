@use 'src/styles/variables/colors';

.box {
  width: 789px;
  display: flex;
  align-items: flex-start;
  padding: 8px;
  margin-bottom: 8px;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  color: colors.$greyscale-900;
  font-size: 14px;
  font-weight: 600;
  background: colors.$gray;
}

.boxWithAssignment {
  width: 690px;
}

.date {
  display: flex;
  align-items: center;
  height: 36px;
  width: 121px;
  padding: 0 8px;
}

.member {
  height: 36px;
  width: 156px;
}

.assignment {
  width: 214px;
}

.logged {
  width: 112px;
}

.note {
  width: 190px;
}

.menu {
  height: 36px;
  display: flex;
  align-items: center;
}

.input {
  input {
    line-height: 16px;
  }
}

.checkboxLabel {
  height: 26px;
  margin: 4px 0;
  padding: 0 12px;
  border-right: 1px solid colors.$greyscale-300;
  display: flex;
  justify-content: center;

  .checkboxLabelText {
    color: colors.$secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .active {
    color: colors.$greyscale-900;
  }
}
