@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.inputIcon {
  path {
    fill: colors.$greyscale-500;
  }
}

.clearIcon {
  display: none;
  cursor: pointer;
  padding-left: 5px;

  &.visible {
    display: flex;
  }
}
