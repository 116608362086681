@use 'src/styles/variables/colors';

$seniorityFlexBasis: 100px;
$seniorityLongFlexBasis: 128px;
$dateFlexBasis: 126px;
$rateFlexBasis: 140px;
$menuFlexBasis: 50px;

.row {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding: 8px 9px 8px 36px;
  background: colors.$greyscale-300;
  position: relative;

  .inputWithSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid colors.$dark-gray;
    border-radius: 4px;
    box-sizing: border-box;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    background-color: colors.$white;
    &:not(.error):hover {
      border-color: colors.$greyscale-900;
    }
    &:not(.error):focus,
    &:not(.error):active,
    &:not(.error):visited,
    &:not(.error):target,
    &:not(.error):focus-visible,
    &:not(.error):focus-within {
      border-color: colors.$greyscale-900;
    }
    fieldset {
      border: none;
      border-radius: 0;
    }
    :global(.MuiAutocomplete-endAdornment) {
      top: auto;
    }

    .unitSelect {
      width: 36%;
    }

    .unitSelectInput {
      div {
        padding: 0;
      }

      input {
        margin: 0;
      }
    }

    &.error {
      border: 1px solid colors.$error;
    }

    input {
      max-height: 34px;
      min-height: 34px;
      box-sizing: border-box;
    }
  }
}

.regularPadding {
  padding: 8px 9px;
}

.senioritySelect {
  display: flex;
  align-items: center;
  flex: 2 1 $seniorityFlexBasis;
}

.senioritySelectFullWidth {
  flex: 2 1 $seniorityLongFlexBasis;
}

.datePicker {
  display: flex;
  align-items: center;
  flex: 1 1 $dateFlexBasis;
}

.menu {
  flex: 0 1 $menuFlexBasis;
  display: flex;
  gap: 6px;
  height: 36px;
  align-items: center;
  justify-content: flex-end;
}

.rate {
  display: flex;
  flex-direction: column;
  flex: 3 1 $rateFlexBasis;
}

.icon {
  width: 22px;
  height: 22px;
}

.iconButton {
  padding: 0;
}

.hidden {
  visibility: hidden;
}

.amountInput {
  margin-right: auto;
  width: 58%;

  input {
    padding-right: 0;
  }
}

.startDatePicker {
  width: 50%;
  background-color: white;
  border-radius: 4px;

  input {
    height: 36px;
    padding: 0 8px;
    box-sizing: border-box;
  }
}

.whiteBackground {
  background-color: colors.$white;
  border-radius: 4px;
}

.dateFormItem {
  background-color: colors.$white;
  border-radius: 4px;

  input {
    height: 10px;
  }
}

.borderBottom {
  position: absolute;
  bottom: -1px;
  left: 8px;
  right: 8px;
  height: 1px;
  background-color: colors.$greyscale-300;
}

.confirmModalBox {
  display: block;
  width: 500px;
  height: 140px;
}

.radioItem {
  color: colors.$greyscale-900;
}

.radioItemContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0 4px 24px;
}
