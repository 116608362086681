.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.childrenBox {
  display: flex;
  gap: 8px;
}

.displayNone {
  display: none;
}
