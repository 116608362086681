@use 'src/styles/variables/colors';

.selectOptionDescription {
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-600;
}

.icon {
  &.open {
    transform: rotate(-180deg);
  }
}

.menuItem {
  width: 320px;
  display: flex;
  align-items: flex-start;
  padding: 10px 16px;
  white-space: initial;
  border-bottom: 1px solid colors.$greyscale-400;
}

.doneIconBox {
  display: flex;
  align-items: flex-start;
  min-width: 24px;
  margin-right: 12px;

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: colors.$greyscale-700;
    }
  }
}

.removeButton {
  display: flex;
  align-items: center;
  margin-left: 35px;
  gap: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: colors.$failure;

  svg {
    height: 24px;
    width: 24px;

    path {
      fill: colors.$failure;
    }
  }
}
