@use 'src/styles/variables/colors';

.projectColor {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin-right: 12px;
}

.projectName {
  font-size: 2.2rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
}

.clientName {
  max-width: 200px;
  color: colors.$greyscale-500;
  font-weight: 600;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.divider {
  height: 1.7rem;
  width: 1px;
  background-color: colors.$greyscale-500;
  margin: 0 16px;
}
