@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-animation {
  animation: fade 0.5s;
}

.transition-02 {
  transition: 0.2s;
}

.transition-05 {
  transition: 0.5s;
}
