@use 'src/styles/variables/colors';

.optionsHeader {
  display: flex;
  gap: 10px;
  padding: 14px 0;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: colors.$greyscale-600;
  border-bottom: 1px solid colors.$greyscale-300;
}

.optionBox {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  height: 36px;
}

.checkboxLabelText {
  color: colors.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.active {
  color: colors.$greyscale-900;
}
