@use 'src/styles/variables/colors';

.wrapper {
  // looks cool?
  // background: radial-gradient(50% 50% at 50% 50%, #e5f0f6 0%, #f7fbfe 100%);
  background-color: colors.$gray;
  height: 100%;
  display: flex;
}

.content {
  //border-radius: 16px 0 0 0;
}

.container {
  height: calc(100% - 61px);
}
