@use 'src/styles/variables/colors';

$headerAndSearchHeight: 140px;

.drawer {
  height: auto;
  max-height: max-content;
}

.container {
  min-height: calc(100vh - #{$headerAndSearchHeight});
  height: auto;
}

.listTitle {
  margin-bottom: 16px;
  color: colors.$greyscale-900;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.memberItem {
  height: 54px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$greyscale-900;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.memberOpacity {
  opacity: 0.4;
}

.memberItemTitle {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.memberDate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
