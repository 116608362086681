@use 'src/styles/variables/colors';

.menuButton {
  border-radius: 4px;
}

.menuButtonActive {
  background-color: colors.$button-hover;
}

.menuIcon {
  min-width: 24px;
}

.iconBox {
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}
