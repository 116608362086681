@use 'src/styles/variables/colors';

.confirmModal {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.buttonBox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 12px;
  padding: 18px 24px;
  border-top: 1px solid colors.$divider;
}

.content {
  padding: 24px;
  font-size: 14px;
  color: colors.$greyscale-600;
}

.deleteButton {
  background-color: colors.$orange-dark;

  &:hover {
    background-color: colors.$orange;
  }

  path {
    fill: colors.$white;
  }
}
