@use 'src/styles/variables/colors';

.value {
  font-size: 20px;
  font-weight: 600;
  color: colors.$typo-primary;
}

.relative {
  display: inline;
  margin-left: 10px;
  padding: 3px 8px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 3px;

  &Positive {
    color: colors.$green-dark;
    background-color: colors.$green-transparent;
  }

  &Negative {
    color: colors.$red-dark;
    background-color: colors.$red-light;
  }
}
