@use 'src/styles/variables/colors';

.dialog {
  width: 400px;

  &Content {
    padding: 24px 0;
    min-height: 260px;
    overflow-x: hidden;
  }
}

.optionBox {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  height: 36px;
}

.checkboxLabelText {
  color: colors.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.active {
  color: colors.$greyscale-900;
}
