@use 'src/styles/variables/colors';

$expandIconWidth: 24px;
$avatarWidth: 40px;

.sidebar {
  border-bottom: 1px solid colors.$divider;

  &Group {
    width: 100%;
    color: colors.$greyscale-700;
    background-color: colors.$gray;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 24px;
  }

  &Tile {
    padding: 12px 12px 12px 24px;
    height: 64px;
    display: flex;
    align-items: center;

    &Expended {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &Member {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &Icon {
      min-width: 20px;
      min-height: 20px;
      border-radius: 4px;
      margin-right: 12px;
    }

    &Heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - #{$expandIconWidth} - #{$avatarWidth});

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: colors.$typo-primary;
        -webkit-text-stroke: colors.$gray;
      }

      svg {
        flex-shrink: 0;
      }

      &Box {
        max-width: 160px;
      }
    }

    &MemberHeading {
      max-width: 100%;
    }

    &Info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        color: colors.$secondary;
        font-size: 12px;
        line-height: 16px;
        margin-right: 15px;
        display: inline-block;
      }

      p {
        color: colors.$sidebar-divider;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }

      &Projects {
        display: flex;
        align-items: center;
      }

      svg {
        height: 12px;
        width: 12px;
        margin-right: 6px;

        path {
          fill: colors.$greyscale-500;
        }
      }
    }
  }

  &Project {
    height: calc(64px * var(--assignments, 1));
    line-height: calc(64px * var(--assignments, 1));
    display: flex;
    flex-direction: column;
    padding: 23px 0;
    justify-content: flex-start;
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: colors.$typo-primary;
      padding-left: 24px;
    }
  }
}

.projectSidebarTile {
  height: 56px;
}
