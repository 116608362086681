@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.wrapper {
  width: 100%;
  height: 38px;
  padding: 0 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: colors.$greyscale-200;

  &Svg {
    width: 40px;
    padding: 0 10px;
    cursor: pointer;
    height: 100%;
  }
}

.collapse {
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &Icon {
    height: 9px;
    margin-right: 12px;
    transition: transform 300ms ease-out, color 333ms;

    &.closed {
      transform: rotate(-180deg);
      transition: transform 300ms ease-out, color 333ms;
    }
  }
}

.wrapperWithBorders {
  border-radius: 5px;
  border: 1px solid colors.$greyscale-300;
}

.openedWrapperWithBorders {
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}
