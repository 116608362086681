@use 'src/styles/variables/typo';

@each $weight in typo.$weights {
  .weight-#{$weight} {
    font-weight: #{$weight};
  }
}

@each $size, $val in typo.$sizes {
  .text-#{$size} {
    font-size: #{$val};
  }
}

.uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.line-break-anywhere {
  line-break: anywhere;
}
