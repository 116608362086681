@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.paper {
  width: 688px;

  @include for-mobile {
    width: 360px;
    padding: 0 8px;
  }
}

div.drawerBox {
  @include for-mobile {
    padding: 0;
  }
}

.drawerHeader {
  @include for-mobile {
    padding: 8px 0 8px 8px;
  }
}

.container {
  padding: 8px 0 32px 20px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.collapseButton {
  margin-right: 16px;
  padding-right: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: colors.$greyscale-600;

  svg {
    height: 12px;
    transform: rotate(-90deg);
    transition: 0.1s ease-out;
  }

  &Expanded {
    svg {
      transform: rotate(90deg);
    }
  }
}
