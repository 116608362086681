@use 'src/styles/variables/colors';

.dialog {
  min-width: 1000px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 500px;
  max-height: 500px;
}

.table {
  td:not(:last-child),
  th:not(:last-child) {
    border-right: 1px solid colors.$greyscale-300;
  }
}
