@use 'src/styles/variables/colors';

$halfIconHeight: 10px;

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  font-weight: 700;
}

.rightIcon {
  position: absolute;
  right: -6px;
  top: calc(50% - #{$halfIconHeight});

  path {
    fill: colors.$greyscale-400;
  }

  &.active {
    path {
      fill: colors.$greyscale-600;
    }
  }
}

.leftIcon {
  position: absolute;
  left: -6px;
  top: calc(50% - #{$halfIconHeight});

  path {
    fill: colors.$greyscale-400;
  }

  &.active {
    path {
      fill: colors.$greyscale-600;
    }
  }
}

.rotated {
  transform: rotate(180deg);
}

.clickable {
  cursor: pointer;
}

.today {
  background-color: colors.$greyscale-500;
  color: colors.$white;
}
