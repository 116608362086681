@use 'src/styles/variables/colors';

.avatars {
  padding-right: 10px;
  display: flex;
}

.avatarBox {
  border-radius: 50%;
  border: 1.5px solid colors.$white;
}

.button {
  display: flex;
  gap: 4px;
  min-width: fit-content;
}

.label {
  color: colors.$greyscale-900;
}
