@use 'src/styles/variables/colors';

.confirmModal {
  max-width: 600px;
}

.datePicker {
  width: 50%;
}

.memberItem {
  height: 54px;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$greyscale-900;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.memberOpacity {
  opacity: 0.4;
}

.memberItemTitle {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.memberDate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
