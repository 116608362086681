@use 'src/styles/variables/colors';

.capacityBox {
  width: 20px;
  height: 20px;
  padding: 7px;
}

.capacityMarker {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: colors.$greyscale-400;

  &FullTime {
    background-color: colors.$accent-400;
  }

  &PartTime {
    background-color: colors.$yellow-400;
  }

  &Overtime {
    background-color: colors.$failure;
  }
}
