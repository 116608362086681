@use 'src/styles/variables/colors';

.assignment {
  width: 100%;
  height: 48px;
  display: flex;
  box-sizing: content-box;
  align-items: center;

  &Project {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 36px;

    &Assigned {
      position: relative;
      line-height: 32px;
      text-overflow: ellipsis;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &:hover {
        z-index: 100;
      }

      .projectLine {
        display: flex;
        width: 100%;
        height: 28px;
        border-radius: 6px;
        background-color: colors.$white;
        border: 1px solid;
        user-select: none;
        overflow: hidden;

        &:hover {
          box-shadow: 0 4px 15px 0 colors.$shadow;
        }

        &HoverDisabled {
          &:hover {
            box-shadow: none;
          }
        }
      }

      .projectLineTile {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .projectLineLabel {
        position: absolute;
        top: 0;
        left: 0;
        min-width: max-content;
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        padding-left: 6px;
        font-weight: 600;
        line-height: 16px;
      }

      .projectMarker {
        width: 4px;
        height: 16px;
        border-radius: 20px;
      }

      .projectLineDragging {
        box-shadow: 0 4px 10px colors.$secondary;
      }

      .projectLineTileRequest {
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 5px,
          rgba(255, 255, 255, 1) 5px,
          rgba(255, 255, 255, 1) 12px
        );
      }

      .projectExtendedLine {
        width: 100%;
        height: 40px;
        background-color: colors.$white;
        filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.05));
        display: flex;
        overflow: hidden;
        flex-direction: column;

        &Header {
          background-color: colors.$secondary;
          width: 100%;
          height: 4px;
        }

        &Content {
          box-sizing: border-box;
          padding: 8px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: colors.$greyscale-800;

          &Title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 16px;
          }

          svg {
            min-width: 16px;
            min-height: 16px;
          }
        }
      }

      &Continues {
        .projectLine {
          border-radius: 6px 0 0 6px;
        }
      }

      &Last {
        .projectLine {
          border-radius: 0 6px 6px 0;
        }
      }

      &Last#{&}Continues {
        .projectLine {
          border-radius: 0;
        }
      }

      &Interactive {
        cursor: pointer;
      }
    }
  }
}

.projectLineWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  &Edge {
    position: absolute;
    bottom: 4px;
    height: 28px;
    width: 10px;
    z-index: 2;
    box-sizing: border-box;
    cursor: col-resize;

    &Start {
      left: 0;
    }

    &End {
      right: 0;
    }
  }
}

.cursorDefault {
  cursor: default;
}

.draggableContentWrapper {
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 4px 0;
}

.tileMinHeight {
  min-height: 56px;
}

.assignmentRow {
  width: 100%;
  height: 48px;
  display: flex;
  box-sizing: content-box;
  align-items: center;
}

.continuousAssignmentArea {
  position: absolute;
  top: 4px;
  bottom: 4px;
  border-radius: 6px;

  &Background {
    background: white;
    z-index: -1;
  }
}
