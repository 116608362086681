.routeWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.routeWrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}
