@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.emptyState {
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-weight: typo.$bolder;
  color: colors.$greyscale-500;
  text-transform: uppercase;
}
