@use 'src/styles/variables/colors';

.navLink {
  color: colors.$greyscale-900;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.checkboxLabelText {
  color: colors.$secondary;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.active {
  color: colors.$greyscale-900;
}
