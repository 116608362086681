@use 'src/styles/variables/colors';

.resourcePlanning {
  overflow: auto;
}

.tabs {
  border-bottom: 1px solid colors.$divider;
  padding: 0 30px 4px;
}

.viewHeading {
  color: colors.$greyscale-900;
  padding: 10px 30px;
}
