@use 'src/styles/variables/spacing';

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

@each $key, $padding in spacing.$paddings {
  .p-#{$key} {
    padding: #{$padding};
  }

  .px-#{$key} {
    padding-left: #{$padding};
    padding-right: #{$padding};
  }

  .py-#{$key} {
    padding-top: #{$padding};
    padding-bottom: #{$padding};
  }

  .pl-#{$key} {
    padding-left: #{$padding};
  }

  .pr-#{$key} {
    padding-right: #{$padding};
  }

  .pb-#{$key} {
    padding-bottom: #{$padding};
  }

  .pt-#{$key} {
    padding-top: #{$padding};
  }
}

@each $key, $margin in spacing.$margins {
  .m-#{$key} {
    margin: #{$margin};
  }

  .mx-#{$key} {
    margin-left: #{$margin};
    margin-right: #{$margin};
  }

  .my-#{$key} {
    margin-top: #{$margin};
    margin-bottom: #{$margin};
  }

  .ml-#{$key} {
    margin-left: #{$margin};
  }

  .mr-#{$key} {
    margin-right: #{$margin};
  }

  .mb-#{$key} {
    margin-bottom: #{$margin};
  }

  .mt-#{$key} {
    margin-top: #{$margin};
  }
}
