@use 'src/styles/variables/colors';

$headerAndSearchHeight: 140px;

.container {
  height: auto;
  max-height: max-content;
}

.form {
  min-height: calc(100vh - #{$headerAndSearchHeight});
  height: auto;
}

.hideButton {
  color: colors.$greyscale-600;

  path {
    fill: colors.$greyscale-600;
  }
}

.memberTitle {
  color: colors.$greyscale-700;
  font-size: 14px;
  font-weight: 600;
}

.searchBox {
  position: sticky;
  top: 64px;
  margin-top: -16px;
  padding: 16px 0 24px;
  z-index: 10;
  background-color: colors.$white;

  > div {
    width: 100%;
  }
}

.tag {
  box-sizing: border-box;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid colors.$greyscale-400;
}

.tagIcon {
  height: 16px;
  width: 16px;
  margin-right: 4px;

  path {
    fill: colors.$greyscale-600;
  }
}

.bottomBlock {
  margin-left: -24px;
  margin-right: -24px;
  position: sticky;
  bottom: 0;
  box-shadow: 0px -4px 20px rgba(87, 126, 148, 0.15);
}

.bottomBlock .accordion {
  box-shadow: none;
  position: relative;
  margin: 0;

  &Summary {
    min-height: 40px;
    height: 40px;
    padding: 10px 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: colors.$greyscale-900;
  }

  &Icon {
    width: 24px;
    height: 24px;
    padding: 0;
    top: -20px;
    border: 1px solid colors.$divider;
    background-color: colors.$white;
  }

  &Details {
    padding: 8px 24px 24px;
    display: flex;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.buttons {
  display: flex;
  padding: 18px 16px;
  gap: 8px;
  background-color: colors.$white;
  border-top: 1px solid colors.$divider;
}

.showAllIcon {
  height: 18px;
  width: 18px;
  margin-right: -4px;
}

.emptyCountChip {
  background: colors.$greyscale-300;
  color: colors.$greyscale-500;
}
