@use 'src/styles/variables/colors';

.content {
  width: 320px;
}

.input {
  font-size: 12px;
  font-weight: 600;
  color: colors.$typo-primary;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 8px;

  &Open,
  &.editable:hover {
    background-color: colors.$divider;
    cursor: pointer;
  }

  &Open {
    border-bottom: 1px solid colors.$greyscale-600;
    margin-bottom: -1px;
  }
}
