@use 'src/styles/variables/colors';

.headerButtonContainer {
  padding-left: 16px;
  display: flex;
  gap: 8px;
}

.rateCardButton {
  min-width: 117px;
}

.headerButton {
  font-size: 14px;
  color: colors.$secondary;

  & .icon {
    width: 20px;
    margin-right: -4px;
    path {
      fill: colors.$greyscale-550;
    }
  }
}

.insightsButton {
  min-width: 117px;

  & .icon {
    width: 24px;
  }
}

.leavesBalanceButton {
  min-width: 156px;
}

.activityHistoryButton {
  padding: 7px;
  border-radius: 4px;

  path {
    fill: colors.$greyscale-600;
  }
}

.menuButton {
  background: colors.$white;
  border: 1px solid colors.$button-border;
  border-radius: 6px;
  padding: 7px;
  margin-right: 8px;

  & .icon {
    path {
      fill: colors.$secondary;
    }
  }

  &Opened {
    background: colors.$gray;
    border: 1px solid colors.$gray;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 10px 12px;
}

.deleteButtonColor {
  color: colors.$failure;
}

.menuIcon {
  width: 20px;
  height: 20px;
  margin-right: 4px;

  path {
    fill: colors.$secondary;
  }
}

.deleteIconColor {
  path {
    fill: colors.$failure;
  }
}

.insightsIcon {
  width: 26px;
  height: 26px;
  margin-left: -4px;
}
