$normal: 400;
$bold: 600;
$bolder: 700;

$weights: (400, 500, 600, 700);

$sizes: (
  '10': 1rem,
  '12': 1.2rem,
  '13': 1.3rem,
  '14': 1.4rem,
  '16': 1.6rem,
  '18': 1.8rem,
  '20': 2rem,
  '22': 2.2rem,
  '25': 2.5rem,
);
