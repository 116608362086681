@use 'src/styles/variables/colors';

.backButton {
  background: colors.$white;
  border: 1px solid colors.$light-gray;
  border-radius: 6px;
  transform: rotate(90deg);

  & .icon {
    path {
      fill: colors.$secondary;
    }
  }
}

// we're not supporting this feature for IE
.sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1001;
  background: colors.$white;
  overflow: unset;
  border-bottom: 1px solid colors.$divider;
  border-radius: 16px 0 0 0;
}
