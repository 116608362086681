@use 'src/styles/variables/colors';

.buttonGroup {
  background-color: colors.$white;
  border-radius: 8px;

  button {
    padding: 5px 12px;
    border-radius: 8px;
  }
}
