@use 'src/styles/variables/colors';

.dialog {
  width: 560px;

  &Content {
    padding: 24px 0;
    min-height: 120px;
    overflow: hidden;
  }
}

.dropZone {
  position: relative;
  height: 217px;
  padding: 24px;
  margin: 0 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed colors.$greyscale-400;
  border-radius: 4px;
  cursor: pointer;
}

.text {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-800;
}

.description {
  font-size: 14px;
  line-height: 20px;
  color: colors.$greyscale-600;
}

.fileInput {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;
}

.fileList {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 18px;
}
