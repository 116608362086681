@use 'src/styles/variables/colors';

.reportingToTitle {
  max-width: 200px;
  letter-spacing: 0.5px;
}

div.tag {
  box-sizing: border-box;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid colors.$greyscale-400;
}
