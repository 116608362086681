@use 'src/styles/variables/colors';

.table {
  overflow: scroll;
  border: 1px solid colors.$divider;
  max-height: 100%;
  padding-bottom: 11px;

  .row {
    border-bottom: 1px solid colors.$divider;
    background-color: colors.$white;

    &:last-child {
      border-bottom: none;
    }
  }

  .cell {
    position: relative;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid colors.$divider;

    &.lastCalendarCell {
      border-right: none;
    }
  }

  .totalCell {
    background-color: colors.$greyscale-200;
  }

  .weekendCell {
    background-color: colors.$gray;
  }

  .header {
    top: 0;
  }

  .footer {
    bottom: 0;
  }

  .header,
  .footer {
    position: sticky;
    z-index: 5;
    box-shadow: inset 0 -1px 0 0 colors.$divider;

    .row {
      height: 48px;
      border-bottom: 1px solid colors.$divider;
    }

    .cell {
      background-color: colors.$gray;
      font-size: 12px;
      font-weight: 600;
      color: colors.$secondary;

      &.weekend {
        background-color: colors.$gray;
      }

      &.name {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        justify-content: flex-start;
        padding: 0 24px;
      }
    }

    .total {
      font-size: 14px;
      color: colors.$typo-primary;
    }
  }

  .footer {
    .footerName {
      justify-content: flex-start;
      padding: 0 24px;
    }

    .row {
      border-top: 1px solid colors.$divider;
    }
  }

  .body {
    .row {
      height: 64px;
    }
  }

  [data-sticky-td] {
    background-color: colors.$white;

    .cell {
      border-right: none;
    }
  }

  [data-sticky-last-left-td] {
    border-right: 2px solid colors.$divider;
    background-color: inherit;
  }

  [data-sticky-first-right-td] {
    border-left: 2px solid colors.$divider;
    background-color: inherit;
  }

  .empty {
    padding: 36px 0;
    position: sticky;
    left: 0;
  }
}
