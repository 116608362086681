@use 'src/styles/variables/colors';

.container {
  display: flex;
  padding: 4px 0 4px 4px;
  border-radius: 4px;
  background: colors.$greyscale-900;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
}

.colorBox {
  display: flex;
  height: available;
  min-width: 4px;
  border-radius: 2px;
}

.contentBox {
  display: flex;
  padding: 0 12px;
  flex-direction: column;
  align-items: flex-start;
}

.titleBox {
  padding-bottom: 8px;
  border-bottom: 1px solid colors.$greyscale-700;
  margin-bottom: 8px;
}

.title {
  width: 100%;
  min-width: max-content;
  color: colors.$white;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.additionalTitle {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: colors.$greyscale-500;
}

.value {
  color: colors.$white;
}
