@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.fileCell {
  &Button {
    height: 34px;
    background-color: transparent;
    font-size: 14px;
    font-weight: typo.$bolder;
    color: colors.$typo-primary;

    &:hover {
      background-color: colors.$divider;
    }

    &.disabled {
      background-color: transparent;
    }
  }

  &Icon {
    width: 16px;
    height: 16px;
    color: colors.$typo-primary;

    & path {
      fill: colors.$greyscale-500;
    }
  }
}

.fileName {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.downloadButton {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.buttonBox {
  padding: 10px 18px 4px;
  border-top: 1px solid colors.$greyscale-300;
}

.emptyMessage {
  min-height: 40px;
  align-items: center;
}
