@use 'src/styles/variables/colors';

.additionalUserInfoOverride {
  word-break: break-word;
  width: 132px;
}

.titleClassNameOverride {
  color: colors.$divider;
}

.divider {
  margin: 4px 0;
  height: 1px;
  background-color: colors.$menu-divider;
}

.iconButton {
  border-radius: 2px;

  &:hover {
    background-color: colors.$button-icon-hover;
  }
}
