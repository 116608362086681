@use 'src/styles/variables/colors';
@use 'src/styles/variables/mediaQuery' as *;

.unitField {
  min-width: 168px;
}

.selectOptionDescription {
  font-size: 12px;
  line-height: 20px;
  color: colors.$greyscale-600;
}

.endAdornment {
  color: colors.$greyscale-600;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  cursor: default;
}

.pmBox {
  display: flex;
  gap: 48px;
  margin-bottom: 24px;

  @include for-mobile {
    gap: 20px;
  }
}

.datePickersBox {
  display: flex;
  gap: 16px;

  @include for-mobile {
    gap: 2px;
  }
}

.twoItemsBox {
  display: flex;
  gap: 48px;

  @include for-mobile {
    flex-direction: column;
    gap: 0;
  }
}

.sectionTitle {
  margin: 48px 0 24px;
  color: colors.$typo-primary;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}
