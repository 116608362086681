@use 'sass:meta';
@use 'src/styles/variables/colors';

@each $name, $content in module-variables(colors) {
  .text-#{$name} {
    color: $content;
  }

  .bg-#{$name} {
    background-color: $content;
  }
}

.danger-btn {
  color: colors.$delete;
  border: 1px solid colors.$delete;
  &:hover {
    background-color: colors.$delete-hover;
    color: colors.$white;
    border: 1px solid colors.$delete-hover;
  }
}

.removeIconButton {
  &:hover {
    & path {
      fill: colors.$remove-icon-hover;
    }
  }
}

.editIconButton {
  height: 30px;

  &:hover {
    & path {
      fill: colors.$edit-icon-hover;
    }
  }
}

.white-background {
  background-color: white;
}

.gray-color {
  color: colors.$greyscale-500;
}
