@use 'src/styles/variables/colors';

$leaveTypes: 268px;
$limit: 63px;
$accrualDateMax: 202px;
$accrualDateMin: 192px;
$menu: 52px;

.row {
  display: flex;
  align-items: flex-start;
  min-width: 552px;
  min-height: 52px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  background-color: colors.$greyscale-300;
  font-size: 14px;
  font-weight: 600;

  .leaveType {
    width: $leaveTypes;
    min-height: 36px;
  }

  .leaveTypeInput {
    & > div {
      min-height: 36px;
      padding: 4px 0 2px 8px;
    }

    input {
      margin: 0;
    }
  }
}

.title {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
}

.item {
  line-height: 18px;
  border-radius: 4px;
}

.iconButton {
  padding: 0;
}

.limit {
  max-width: $limit;
  min-width: $limit;

  & > div {
    background-color: colors.$white;
  }

  input {
    padding: 9px 8px;
  }

  p {
    width: max-content;
  }
}

.accrualDate {
  display: flex;
  flex-grow: 1;
  max-width: $accrualDateMax;
  min-width: $accrualDateMin;
}

.menu {
  padding: 7px 0;
  height: 36px;
  width: $menu;
  display: flex;
  gap: 8px;
}

.secondaryColor {
  color: colors.$greyscale-500;
}
