@use 'src/styles/variables/colors';

.checkboxLabel {
  margin: 0;

  .checkboxLabelText {
    color: colors.$secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .active {
    color: colors.$greyscale-900;
  }
}
