@use 'src/styles/variables/colors';

$title: 278px;
$effectiveFrom: 278px;
$menu: 52px;

.row {
  display: flex;
  align-items: center;
  min-width: 552px;
  height: 52px;
  padding: 8px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid colors.$greyscale-300;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: colors.$greyscale-200;
  }
}

.iconButton {
  width: 22px;
  height: 22px;
  padding: 1px;

  svg {
    min-width: 18px;
    min-height: 18px;
  }
}

.item {
  margin-left: 8px;
  color: colors.$greyscale-900;
  cursor: pointer;
}

.divider {
  width: 1px;
  min-width: 1px;
  height: 24px;
  margin: -4px 0;
  background-color: colors.$greyscale-300;
}

.title {
  display: flex;
  align-items: center;
  width: $title;
}

.effectiveFrom {
  display: flex;
  align-items: center;
  width: $effectiveFrom;
}

.menu {
  width: $menu;
  display: flex;
  gap: 8px;
}
